import { CircularProgress, DialogContent } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toEnglishDigits, zeroFormat } from '../../../helpers/tools';
import moment from 'jalali-moment';
import _ from 'lodash';
import { detect_bank } from '../../../helpers/bankName';
import { useState } from 'react';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import useSnack from '../../../hooks/useSnack';
import * as DialogShow from '../../../services/redux/dialog';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../../services/redux/user';

const ConfirmCash = ({ data, close, setReLoad, setAmount }: { data: any, close: any, setReLoad: any, setAmount: any }) => {
  const [myLoading, setMyLoading] = useState(false);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const dispatch = useDispatch<any>();

  const submit = async () => {
    setMyLoading(true);

    const body: any = {
      amount: Number(toEnglishDigits(data?.amount?.toString())),
      card: data?.card?._id,
    }

    const result = await gather(`${config.api}/v1/payment/cashout`).post(body);

    if (result.message === 200) {
      setReLoad(new Date().toString())
      snackUse.showSaccess("عملیات با موفقیت انجام شد");
      setAmount(0);
      dispatch(DialogShow.hide());
      await dispatch(getProfile())
    } else {
      if (result.message == "10029") {
        snackUse.showError(t("10029A"));
      } else {
        snackUse.showError(t(result.message) || t("99999"));
      }
    }

    setMyLoading(false);
  }

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw width_430'>
        <div className='body'>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment().locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p> کیف پول</p>
            <p>کیف پول اسپات</p>
          </div>
          <div className='dec'>
            <p>مبلغ</p>
            <p>{zeroFormat(data?.amount)} &nbsp;<b>تومان</b> </p>
          </div>
          <div className='dec'>
            <p>شماره کارت</p>
            <div className='typeCoin'>
              <p className='ltr'>{data?.card?.number}</p>
              <LazyLoadImage src={`${!_.isEmpty(detect_bank(data?.card?.number).bank_logo) ? detect_bank(data?.card?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
            </div>
          </div>
          <div className='dec'>
            <p>کارمزد پایا</p>
            <p>{data?.fee ?? 0} &nbsp;<b>تومان</b> </p>
          </div>
          <div className='dec'>
            <p>مقدار نهایی</p>
            <p>{zeroFormat(data?.final)}  &nbsp;<b>تومان</b> </p>
          </div>
          <div className='btns'>
            {
              myLoading
                ? <div className="loadingCircular h41"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                : <button className="finalCheckBtn" onClick={submit} > تایید  </button>
            }
            <button className="finalCheckBtn btnCancel" onClick={() => !myLoading && close()} > لغو  </button>
          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default ConfirmCash