import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NumericFormat } from 'react-number-format';
import { config } from '../../../config/config';
import { removeComma, toEnglishDigits } from '../../../helpers/tools';

const ItemCoinGift = ({ data, handleAdd }: { data: any, handleAdd: any }) => {
  const [inputState, setInputSatet] = useState(0);
  const [errorInput, seterrorInput] = useState(false);

  const handelClick = (e: any) => {
    let item = e;

    if (inputState <= 0) {
      seterrorInput(true);
      setTimeout(() => {
        seterrorInput(false)
      }, 1000);
      return
    }
    item["giftAmount"] = Number(toEnglishDigits(inputState?.toString()));
    handleAdd(item)
    setInputSatet(0)
  }


  return (
    <div className="item">
      <div className="name">
        <LazyLoadImage crossOrigin='anonymous'
          src={data?.symbol == "tmn"
            ? '/icons/iranflag.svg'
            : `${config.imgUrl}${data?.symbol?.toUpperCase()}.png`
          }
          alt="" />
        <NumericFormat className={`inputNumeric ${errorInput ? "brRed" : ""}`} id={data?._id} allowNegative={false} thousandSeparator={true}
          value={inputState > 0 ? inputState : ''} onChange={(e) => setInputSatet(removeComma(e.target.value))}
          placeholder={" مقدار " + data?.nameFa} />
      </div>
      <div className="balance" onClick={() => setInputSatet(removeComma(data?.balance?.replaceAll(" ","")?.replaceAll("موجودی","")?.replaceAll(":","")))}>
        <p>{data?.balance}</p>
      </div>
      <div className="btnAdd" onClick={() => handelClick(data)}>
       افزودن
      </div>
    </div>
  );
}

export default ItemCoinGift;
