import React, { useState, useEffect } from 'react';
import '../asset/css/offlineNotification.scss';
import { CircularProgress } from '@mui/material';

const OfflineNotification = () => {
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // پاکسازی رویدادها
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {isOffline &&
        <div className="offline-notification">
          <p className='t1'>اتصال اینترنت شما قطع است. لطفاً اتصال خود را بررسی کنید.</p>
          <CircularProgress size={15} className='prog' style={{ color: "#fff" }} />
        </div>
      }
    </>
  );
};

export default OfflineNotification;
