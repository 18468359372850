import { DialogContent, Divider } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'
import { statusFA } from '../../../config/variable'
import moment from 'jalali-moment'
import { zeroFormat } from '../../../helpers/tools'
import { IoMdCloseCircle } from 'react-icons/io'
import { config } from '../../../config/config'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useSnack from '../../../hooks/useSnack'

const DetailWDCoin = ({ data, deposit, close }: any) => {
  const snackUse = useSnack();

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw'>
        <div className='body'>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>ثبت درخواست</h4>
              <p>{data?.createdAt && moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>پردازش سیستم</h4>
              <p>{data?.createdAt && moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' className='line' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step'>
            <div className={`circlePoint ${data?.status}`}>
              <span></span>
            </div>
            <div className={`textDate ${data?.status}`}>
              <h4 id={`${data?.status}`}>{statusFA[data?.status]}</h4>
              <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
          </div>
          {/* <div className='textDec'>لطفا توجه داشته باشید پس از تکمیل شدن پرداخت یک ایمیل دریافت خواهید کرد.
            <NavLink to={"#"}>
              ایمیل دریافت نکرده ام
            </NavLink> .</div> */}
          {/* <NavLink to={"#"} className='reportWarning'>
            <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconDangerRed.svg" alt="" />
            <p>گزارش کلاهبرداری</p>
          </NavLink> */}
          <Divider className='divder' />
          <div className='dec'>
            <p>شناسه درخواست</p>
            <div className='shaba'>
              <CopyToClipboard text={data?.uniqueId} onCopy={() => { data?.uniqueId && snackUse.showSaccess("آدرس کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?.uniqueId}</p>
            </div>
          </div>
          <div className='dec'>
            <p>وضعیت</p>
            <div className='status confirm'>
              {data?.status == "reject"
                ? <IoMdCloseCircle className='close' size={22} />
                : data?.status == "confirm" ? <span className='icon-TickSquare'></span> : <span className='icon-IconInfoCircle'></span>
                // : <LazyLoadImage crossOrigin='anonymous' src={`/icons/${data?.status == "confirm" ? "m-IconTickSquare" : "m-IconInfoCircle"}.svg`} alt="" />
              }
              <p id={`${data?.status}`}>{statusFA[data?.status]}</p>
            </div>
          </div>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p>{deposit ? "واریز" : "برداشت"} کیف پول</p>
            <p>کیف پول اسپات</p>
          </div>
          <div className='dec'>
            <p>رمز ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
              <p className='ltr'>{data?.coin?.symbol?.toUpperCase()}</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار</p>
            <p className='ltr'>{zeroFormat(data?.amount)}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          <div className='dec'>
            <p>کارمزد شبکه</p>
            <p className='ltr'>{data?.fee ?? 0}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          <div className='dec'>
            <p>مقدار نهایی</p>
            <p className='ltr'>{zeroFormat(data?.final)}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          <div className='dec'>
            <p>{deposit ? "شبکه" : "دریافت کننده"}</p>
            {deposit
              ? <p className='ltr'>{data?.network?.name ?? "---"}</p>
              : <div className='shaba'>
                <CopyToClipboard text={data?.receiver} onCopy={() => { data?.receiver && snackUse.showSaccess("آدرس کپی شد") }}>
                  <span className='icon-IconCopy3 cPointer'></span>
                </CopyToClipboard>
                <p className='ltr'>{data?.receiver?.length > 18 ? data?.receiver?.slice(0, 6) + ". . ." + data?.receiver?.slice(-6, data?.receiver?.length) : data?.receiver}</p>
              </div>}
          </div>
          {data?.memo
            && <div className='dec'>
              <p>تگ / ممو</p>
              <p className='ltr'>{data?.memo}</p>
            </div>}
          <div className='dec'>
            <p>هش تراکنش (TxID)</p>
            <div className='shaba'>
              <CopyToClipboard text={data?.hash} onCopy={() => { data?.hash && snackUse.showSaccess("هش تراکنش کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <NavLink to={data?.network?.scanner + "/" + data?.hash} target="_blank" ><span className='icon-IconLink2 cPointer'></span></NavLink>
              <p className='ltr'>{data?.hash?.length > 18 ? data?.hash?.slice(0, 6) + ". . ." + data?.hash?.slice(-6, data?.hash?.length) : data?.hash}</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default DetailWDCoin