import React, { useState } from 'react'
import CustomInputText from '../../../components/CustomInputText'
import HeadDialog from '../../../components/HeadDialog';
import { CircularProgress, DialogContent } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CustomSelectBox from '../../../components/CustomSelectBox';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toEnglishDigits } from '../../../helpers/tools';
import moment from 'jalali-moment';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import useSnack from '../../../hooks/useSnack';
import * as DialogShow from '../../../services/redux/dialog';
import { hide } from '../../../services/redux/backdrop';
import { useNavigate } from 'react-router-dom';
import { menu } from '../../../config/menu';
import { getProfile } from '../../../services/redux/user';

const AddNationalAndBirthday = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [nationalCode, setNationalCode] = useState('');
  const [nationalCodeError, setNationalCodeError] = useState(false);
  const [birthDate, setBirthDate]: any = useState({ y: '', m: '', d: '' });
  const [errorBirt, setErrorBirt] = useState(false);
  const [myLoading, setMyLoading] = useState(false);
  const profile: any = useSelector((state: any) => state.user?.profile);
  const snackUse = useSnack();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [family, setFamily] = useState('');


  const send = async () => {
    if (name?.trim().length < 3) {
      snackUse.showError("نام خود را وارد کنید");
      return
    }
    if (family?.trim().length < 3) {
      snackUse.showError("نام خانوادگی خود را وارد کنید");
      return
    }

    if (nationalCode.length !== 10) {
      setNationalCodeError(true);
      setTimeout(() => { setNationalCodeError(false) }, 3000);
      return
    }

    if (_.isEmpty(birthDate.y) || _.isEmpty(birthDate.d) || _.isEmpty(birthDate.m)) {
      setErrorBirt(true);
      setTimeout(() => { setErrorBirt(false) }, 3000);
      return
    }


    setMyLoading(true);
    const body = {
      name: name?.trim(),
      family: family?.trim(),
      address: profile?.address ?? "",
      nationalCode: toEnglishDigits(nationalCode?.toString()),
      birthDate: moment.from(birthDate.y + "-" + birthDate.m + "-" + birthDate.d, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
    }

    const result = await gather(`${config.api}/v1/user/verify`).post(body);

    if (result.code === 200) {
      await dispatch(getProfile())
      dispatch(DialogShow.show(hide));
      navigate(menu.setting.childs.accountUser.url)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={() => dispatch(DialogShow.show(hide))}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw minWidth_450'>
        <div className='dialogContainer useVoucher'>
          <div className='body'>
            <h4>با توجه به نقص اطلاعات حساب کاربری شما ، جهت تکمیل لطفا کد ملی و تاریخ تولد خود را وارد کنید.</h4>
            <div className='inputVoucher'>
              <p>نام*</p>
              <CustomInputText type={"text"} label={''} inputState={name} setInputSatet={setName} className={"py-16"}
                error={false} classStart={"brNone"}
                startEle={<span className='icon-userprofile'></span>} />
              <p>نام خانوادگی*</p>
              <CustomInputText type={"text"} label={''} inputState={family} setInputSatet={setFamily} className={"py-16"}
                error={false} classStart={"brNone"}
                startEle={<span className='icon-userprofile'></span>} />
              <p>کد ملی*</p>
              <CustomInputText type={"number"} label={''} inputState={nationalCode} setInputSatet={setNationalCode} className={"py-16"}
                error={nationalCodeError} classStart={"brNone"}
                startEle={<span className='icon-kartmelli'></span>} />
              <div className="birthDate">
                <span>تاریخ تولد*</span>
                <div>
                  <CustomSelectBox error={errorBirt} text='روز' rang={31} sum={1} selecting={(e: any) => setBirthDate((birt: any) => ({ ...birt, d: e }))} />
                  <CustomSelectBox error={errorBirt} text='ماه' rang={12} sum={1} selecting={(e: any) => setBirthDate((birt: any) => ({ ...birt, m: e }))} />
                  <CustomSelectBox error={errorBirt} text='سال' rang={65} sum={1330} selecting={(e: any) => setBirthDate((birt: any) => ({ ...birt, y: e }))} />
                </div>
              </div>
            </div>
            {myLoading
              ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <button className='finalCheckBtn' onClick={send}>تایید</button>}
          </div>
        </div>
      </DialogContent>

    </>
  )
}

export default AddNationalAndBirthday