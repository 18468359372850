import React, { useEffect, useState } from 'react'
import CustomInput from '../../components/CustomInput'
import * as BackdropShow from "../../services/redux/backdrop";
import { config } from '../../config/config';
import gather from '../../helpers/gather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import CustomSelectBoxLabel from '../../components/CustomSelectBoxLabel';
import { detect_bank } from '../../helpers/bankName';
import _ from 'lodash';
import History from './items/History';
import { menu } from '../../config/menu';
import NavChangeDeposit from './items/NavChangeDeposit';
import { CircularProgress, Divider } from '@mui/material';
import { removeComma, toEnglishDigits, zeroFormat } from '../../helpers/tools';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import DepositConfirm from './items/DepositConfirm';
import * as DialogShow from '../../services/redux/dialog';
import WarningVPN from './dialogs/WarningVPN';
import useSnack from '../../hooks/useSnack';
import Footer from '../../components/Footer';
import { useLocalStorage } from 'usehooks-ts';

const DepositCashMin = () => {
  const dispatch = useDispatch<any>();
  const cardBanckList = useSelector((state: any) => state.user.cards);
  const setting = useSelector((state: any) => state.publicApis?.settingApi);
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState(false);
  const [card, setCard]: any = useState(Object.values(cardBanckList)[0] ?? {});
  const [cardError, setCardError]: any = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [maxError, setMaxError]: any = useState(false);
  const navigate = useNavigate();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);
  const [showConfirmDi, setShowConfirmDi, removeShowConfirmDi] = useLocalStorage('Con_Die', "false");

  const handleAmount = (e: any) => {
    setAmount(e);
    if (Number(e) > 25000000) {
      setMaxError(true)
    } else {
      setMaxError(false)
    }
  }

  const send = async () => {
    let newAmount = Number(toEnglishDigits(amount?.toString()));
    if (_.isEmpty(card)) {
      snackUse.showError("لطفا کارت بانکی خود را انتخاب کنید");
      setCardError(true);
      setTimeout(() => { setCardError(false) }, 3000)
      return;
    }

    if (amount == 0) {
      snackUse.showError(`لطفا مبلغ را وارد کنید`);
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    if (newAmount < setting?.fiat?.deposit?.min) {
      snackUse.showError("مبلغ واریز کمتر از حد مجاز است");
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    if (newAmount > 10000000) {
      snackUse.showError("حداکثر واریز شتابی روزانه 10 میلیون تومان است");
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }


    setMyLoading(true);

    const result = await gather(`${config.api}/v1/payment`).post({
      card: card?._id,
      amount: newAmount,
      callback: process.env.REACT_APP_WEBSIT + menu.financial.childs.depositAll.childs.depositCash.childs.default.url,
    });

    if (result.message === 200) {
      setShowConfirmDi("true")
      snackUse.showSaccess("در حال اتصال به درگاه بانک");
      window.location.href = result?.data?.url;

    } else {
      if (result.code === 503 || result.message === 503) {
        dispatch(DialogShow.show(<WarningVPN close={() => dispatch(DialogShow.hide())} />))
      } else if ( result.code === 403 || result.message === 403) {
        snackUse.showError("دسترسی محدود!  واریز شتابی غیرفعال است و یا فیلترشکن خود را خاموش کنید.");
      } else {
        snackUse.showError(t(result.message) || t("99999"));
      }
    }

    setMyLoading(false);
  }

  const show = () => {
    const success = searchParams.get('success');
    const hash = searchParams.get('hash');
    const message = searchParams.get('message');

    if (showConfirmDi == "true" && success && hash && message) {
      removeShowConfirmDi()
      dispatch(DialogShow.show2(
        <DepositConfirm message={message} success={success}
          txt={message == "200"
            ? "با تشکر از پرداخت شما"
            : "تراکنش ناموفق"
          }
          hash={hash}
          close={() => dispatch(DialogShow.hide2())} />));
    }

    // searchParams?.delete('amount');
    searchParams?.delete('hash');
    searchParams?.delete('success');
    searchParams?.delete('message');
    // searchParams?.delete('coin');
    setSearchParams(searchParams);

  }

  useEffect(() => {
    show()
  }, [])

  useEffect(() => {
    document.title = menu.financial.childs.depositAll.childs.depositCash.childs.default.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox'>
                <NavChangeDeposit />
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>1</span></p>
                    <h5>انتخاب شماره کارت</h5>
                  </div>
                  <NavLink className="addCard" to={menu.userBank.childs.account.url}>
                    <span className='icon-IconAddCard'></span>
                    <p>افزودن کارت</p>
                  </NavLink>
                </div>
                <CustomSelectBoxLabel element={
                  <div className='showBanck'>
                    {card?.number
                      ? <><h4 className='p-0 fontCard' >{card?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</h4>
                        <img className='m-0' src={`${!_.isEmpty(detect_bank(card?.number).bank_logo) ? detect_bank(card?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
                      </>
                      : <h4 className='defaultText'><b>شماره کارت خود را انتخاب کنید</b></h4>}
                  </div>}
                  error={cardError} listData={Object.values(cardBanckList) ?? []} label={"شماره کارت"} setInputSatet={setCard} className={" styleBankSelect mb-28 mt-10"} />
                <div className='stepDepWith'>
                  <p><span>2</span></p>
                  <h5>مقدار واریزی</h5>
                </div>
                <CustomInput error={amountError} inputState={amount} decimalScale={0} setInputSatet={handleAmount} label={""} classStart={"brNone"} startEle={<p className='colorGray '>تومان</p>} endEle={""} className={"ltr mb-17"} placeholder={"0.00"} />
                <div className='limitDepositBox none'>
                  <p>حداکثر باقی مانده واریز شتابی امروز</p>
                  <p> 0 <b>تومان</b></p>
                </div>
                <div className="btnsPercentage">
                  <div onClick={() => setAmount(500000)} className='item'><p>500 هزار</p></div>
                  <div onClick={() => setAmount(1000000)} className='item'><p>1 میلیون</p></div>
                  <div onClick={() => setAmount(3000000)} className='item'><p>3 میلیون</p></div>
                  <div onClick={() => setAmount(5000000)} className='item'><p>5 میلیون</p></div>
                  <div onClick={() => setAmount(10000000)} className='item'><p>10 میلیون</p></div>
                </div>
                <Divider className='divider' />
                {maxError
                  ? <div className='warningMaxAmount'>
                    <h3>برای واریز این مبلغ از قابلیت <b onClick={() => navigate(menu.financial.childs.depositAll.childs.depositCash.childs.high.url)}>واریز با شناسه</b> استفاده کنید . از طریق درگاه بانکی واریز تنها تا سقف 25 میلیون تومان امکان پذیر است</h3>
                  </div>
                  : <>
                    <div className='feeFinalAmount'>
                      <div>
                        <p>کارمزد شتابی</p>
                        <span className='icon-IconInfoCircle'></span>
                      </div>
                      <p> {zeroFormat(setting?.fiat?.deposit?.fee)} <b>تومان</b></p>
                    </div>
                    <div className='feeFinalAmount'>
                      <p>مقدار نهایی واریز به کیف پول</p>
                      <p> {amount > setting?.fiat?.deposit?.fee ? zeroFormat(Number(removeComma(amount) - setting?.fiat?.deposit?.fee), 4, false) : 0} <b>تومان</b></p>
                    </div>
                  </>
                }
                {
                  myLoading
                    ? <div className="loadingCircularMin"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                    : <button className={`submit ${maxError && "btnDisable"}`} onClick={() => { !maxError && send() }}>پرداخت کنید</button>
                }
              </div>
              <div className='detailBox'>
                <img className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                <div className='minDepoText'>
                  <img src="/icons/m-IconInfoCircleGreen.svg" alt="" />
                  <p>حداقل میزان واریز 100,000 تومان می‌باشد.</p>
                </div>
                <div className='infoText'>
                  <h5><b>نکات</b> مهم</h5>
                  {/* <p>از صحت <b>آدرس صفحه‌ی پرداخت و بودن در یکی از سایت‌های سامانه‌ی شاپرک</b> مطمئن شوید.</p>
                <p>مطمئن شوید <b>مبلغ نمایش‌ داده‌شده</b> در صفحه‌ی پرداخت درست باشد.</p> */}
                  <p>بعداز پرداخت از طریق درگاه الکترونیک شاپرک ، در صورت عدم شارژ کیف پول تومان ، لطفا صفحه خود را بعداز 2 دقیقه refresh کنید و مجدد بررسی کنید.</p>
                  <p>برای واریز ریال یکی از کارت های تایید شده را انتخاب کنید و اگر کارت تایید شده ندارید در قسمت کارت های بانکی در بخش حساب بانکی اضافه کنید.</p>
                  <p>دقت کنید ، کارت بانکی که قصد ثبت در پروفایلتان دارید باید با کد ملی شما مطابقت داشته باشد.</p>
                  <p>با توجه به ابلاغیه بانک مرکزی در مورد نظام کارمزد شاپرکی و همچنین کارمزد سرویس های پرداخت یاری، واریز ریالی از درگاه پرداخت به حساب کاربری در اکسنوین مشمول کارمزد خواهد بود. </p>
                </div>
              </div>
            </div>
            <History flag={true} reload={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DepositCashMin