import { NavLink } from "react-router-dom"
import { socialLink } from "../config/variable"
import { useLocalStorage } from 'usehooks-ts'


const Footer = () => {
  const [theme] = useLocalStorage('themeEX', "light");
  return (
    <footer className="footer">
      <div className="newContainer">
        <div className="bodyBox">
          <div className="blueBox">
            <div className="des">
              <div className="circle1">
                <div className="circle2">
                  <img crossOrigin='anonymous' src="/icons/m-Calling.svg" alt="" />
                </div>
              </div>
              <div className="text">
                <h4>مشاوره رایگان</h4>
                <p>تیم ما از  شنبه تا جمعه . ۹ صبح تا ۲۴:۰۰ پاسخگو شما خواهند بود</p>
              </div>
            </div>
            <div className="tel">
              <h3><b>021</b> &nbsp; 91302300</h3>
            </div>

          </div>

          <div className="aboutSite">
            <div className="des">
              {/* <img crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" /> */}
              <p>
                اکسنوین استارتاپی پیشرو که در سال 1396، با هدف ارائه پلتفرمی حرفه ای به منظور مبادله دارایی‌های رمزنگاری شده، متولد شد و افتخار میزبانی از +600 هزار کاربر را دارد. اکسنوین با فراهم آوردن سیستم یکپارچه با امنیت بالا، کارمزد مناسب ، کوتاه ترین زمان برای احراز هویت، +300 ارز دیجیتال لیست شده و تیم پشتیبانی حرفه ای توانسته تا تجربه خوشایندی از خرید و فروش امن، سریع و بدون واسطه را برای کاربران فراهم کند.
              </p>
            </div>
            <div className="timeCompany">
              <div className="title">
                <span className="icon-Clock"></span>
                <h4>ساعت کاری مجموعه</h4>
              </div>
              <p>پشتیبانی از طریق تیکت در روزهای اداری از ۸:۰۰ الی ۲۴:۰۰</p>
              {/* <p>تسویه حسابداری از ۹ صبح تا ۲۴:۰۰</p> */}
            </div>
            <div className="downloadApp">
              <div className="title">
                <span className="icon-mobile"></span>
                <h4>دانلود اپلیکیشن</h4>
              </div>
              <div className="iconsDownload">
                <NavLink target="_blank" to={socialLink.bazarEX}><img crossOrigin='anonymous' src="/icons/m_bazarIcon.svg" alt="دانلود از کافه بازار" /></NavLink>
                <NavLink target="_blank" to={socialLink.googlePlayEX}><img crossOrigin='anonymous' src="/icons/m-googlePlayIcon.svg" alt="دانلود از گوگل پلی" /></NavLink>
                <NavLink target="_blank" to={socialLink.pwaEX}><img crossOrigin='anonymous' className="bazar" src="/icons/m-pwaIcon.svg" alt="نسخه وب اپلیکیشن" /></NavLink>
              </div>
            </div>
          </div>
          <div className="socialICons">
            <div className="tel">
              <p>تماس با پشتیبانی</p>
              <p><b>۰۲۱ </b> ۹۱۳۰۲۳۰۰</p>
            </div>
            <div className="icons">
              <NavLink to={socialLink.twitter}><img crossOrigin='anonymous' src="/icons/m-Twitter.svg" alt="" /></NavLink>
              <NavLink to={socialLink.instagram}><img crossOrigin='anonymous' src="/icons/m-Instagram.svg" alt="" /></NavLink>
              <NavLink to={socialLink.telegram}><img crossOrigin='anonymous' src="/icons/m-Telegram.svg" alt="" /></NavLink>
              <NavLink to={socialLink.aparat}><img crossOrigin='anonymous' src="/icons/m-Aparat.svg" alt="" /></NavLink>
            </div>
          </div>
          <hr className="dividerVer" />
          <div className="copyright"><h4>.تمامی حقوق مادی و معنوی برای <b>اکسنوین (سهامی خاص) </b>محفوظ است</h4></div>
        </div>
      </div>
    </footer>

  )
}

export default Footer


