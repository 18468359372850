import { Navigate, Route, Routes } from 'react-router-dom';
import HistoryTicket from './ticket/items/HistoryTicket';
import DefaultTicket from './ticket/items/DefaultTick';
import CreateTicket from './ticket/items/CreateTicket';
import AccountBank from './Profile/AccountBank';
import AccountUser from './Profile/AccountUser';
import ChangePass from './Profile/ChangePass';
import WithdrawCash from './withdrawAll/WithdrawCash';
import { useSelector } from 'react-redux';
import Chat from './ticket/items/Chat';
import { menu } from '../config/menu';
import OrderPro from './orderPro/OrderPro';
import Ticket from './ticket';
import FastOrder from './orderPro/Order';
import Wallet from './wallet';
import Auth from './auth';
import UploadDocUser from './login/items/UploadDocUser';
import DepositCashMin from './depositAll/DepositCashMin';
import DepositCashMax from './depositAll/DepositCashMax';
import DepositCoin from './depositAll/DepositCoin';
import WithdrawCoin from './withdrawAll/WithdrawCoin';
import GoogleAuth from './Profile/GoogleAuth';
import ScrollToTop from '../ScrollToTop';
import OrderCurrency from './orderPro/OrderCurrency';
import UpgradeLevel from './upgradeLevel';
import HistoryDepositFiat from './transactionHistory/HistoryDepositFiat';
import HistoryDepositCoin from './transactionHistory/HistoryDepositCoin';
import HistoryWithdrawFiat from './transactionHistory/HistoryWithdrawFiat';
import HistoryWithdrawCoin from './transactionHistory/HistoryWithdrawCoin';
import Page404 from './erorrPage/404';
import Learning from './learning';
import NotifLsit from './notif';
import LoginPageNew from './loginNew';
import SignupPageNew from './register';
import ForgetPageNew from './forget';
import AlertCoin from './alertCoin';
import Gift from './gift';
import InvitationCode from './invitationCode';
import DepositCashCard from './depositAll/DepositCashCard';

const Main = () => {
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const profile = useSelector((state: any) => state.publicApis?.profile);

  return (
    <main>
      <Routes>
        <Route path='/' element={<Navigate to={menu.order.childs.fastOrder.url} />} />

        <Route path={menu.learning.url + "/:learnParam?/:cIdParam?"} element={<Learning />} />

        <Route path={menu.order.childs.orederPro.url + "/:coinPairParam?"} element={<OrderPro />} />
        <Route path={menu.order.childs.fastOrder.url + "/:coinParam?"} element={<FastOrder />} />

        {userIsLogin &&
          <>
            {profile?.isOld && <Route path={menu.order.childs.currency.url + "/:coinParam?"} element={<OrderCurrency />} />}
            <Route path={menu.alertCoin.url} element={<AlertCoin />} />
            <Route path={menu.giftCode.url} element={<Gift />} />
            {/* <Route path={menu.invitationCode.url} element={<InvitationCode />} /> */}
            <Route path={menu.financial.childs.depositAll.childs.depositCash.childs.default.url} element={<DepositCashMin />} />
            <Route path={menu.financial.childs.depositAll.childs.depositCash.childs.high.url} element={<DepositCashMax />} />
            <Route path={menu.financial.childs.depositAll.childs.depositCash.childs.card.url} element={<DepositCashCard />} />
            <Route path={menu.financial.childs.depositAll.childs.depositCoin.url + "/:coinParam?"} element={<DepositCoin />} />
            <Route path={menu.financial.childs.withdrawAll.childs.withdrawCash.url} element={<WithdrawCash />} />
            <Route path={menu.financial.childs.withdrawAll.childs.withdrawCoin.url + "/:coinParam?"} element={<WithdrawCoin />} />
            <Route path={menu.financial.childs.wallet.url} element={<Wallet />} />

            <Route path={menu.financial.childs.transactionHistory.childs.depositCash.url} element={<HistoryDepositFiat />} />
            <Route path={menu.financial.childs.transactionHistory.childs.depositCoin.url} element={<HistoryDepositCoin />} />
            <Route path={menu.financial.childs.transactionHistory.childs.withdrawCash.url} element={<HistoryWithdrawFiat />} />
            <Route path={menu.financial.childs.transactionHistory.childs.withdrawCoin.url} element={<HistoryWithdrawCoin />} />

            <Route path={menu.userBank.childs.account.url} element={<AccountBank inPage={true} />} />
            <Route path={menu.notifications.url} element={<NotifLsit />} />

            <Route path={menu.upgradeLevel.url} element={<UpgradeLevel />} />

            <Route path={menu.setting.childs.changePass.url} element={<ChangePass />} />
            <Route path={menu.setting.childs.accountUser.url} element={<AccountUser />} />
            <Route path={menu.setting.childs.googleAuth.url} element={<GoogleAuth />} />

            <Route path={menu.ticket.url} element={<Ticket />} >
              <Route path="*" element={<DefaultTicket />} />
              <Route path={menu.ticket.childs.default.url} element={<DefaultTicket />} />
              <Route path={menu.ticket.childs.create.url} element={<CreateTicket />} />
              <Route path={menu.ticket.childs.history.url} element={<HistoryTicket />} />
              <Route path={menu.ticket.childs.chat.url} element={<Chat />} />
            </Route>

            {/* <Route path={menu.auths.url} element={<Auth />} >
              <Route path={menu.auths.childs.uploadDoc.url} element={<UploadDocUser inPage={true} />} />
            </Route> */}
          </>
        }

        <Route path={"*"} element={<Page404 />} />
        {!userIsLogin ?
          <>
            <Route path={"/login"} element={<LoginPageNew />} />
            <Route path={"/register"} element={<SignupPageNew />} />
            <Route path={"/forget"} element={<ForgetPageNew />} />
          </>
          : <>
            <Route path='/login' element={<Navigate to={menu.order.childs.fastOrder.url} />} />
            <Route path='/register' element={<Navigate to={menu.order.childs.fastOrder.url} />} />
            <Route path='/forget' element={<Navigate to={menu.order.childs.fastOrder.url} />} />
          </>}
      </Routes>
      {/* <Footer /> */}
      <ScrollToTop />
    </main>
  )
}

export default Main;