import { createSlice } from "@reduxjs/toolkit"

const stateDataSaver: { [key: string]: any } = {
  userIsLogin: false as boolean,
  userIsReady: false as boolean,
  dataTicket: {} as any,
  showDropDown: { item: '', show: false } as any,
  orderReload: {} as any,
  orderForOderBook: {} as any,
  versionUpdate: "" as any,
  orderSocketData: {} as any,
}

export const DataSaver = createSlice({
  name: "sataSaver",
  initialState: stateDataSaver,
  reducers: {
    addTicketChat: (state, action) => {
      state.dataTicket = action.payload;
    },
    handleMenuDrop: (state, action) => {
      state.showDropDown = action.payload
    },
    reloadApiOrder: (state, action) => {
      state.orderReload = action.payload
    },
    addOrderForOderBook: (state, action) => {
      state.orderForOderBook =action.payload
    },
    changeUserIsLogin: (state, action) => {
      state.userIsLogin = action.payload
    },
    changeUserIsReady: (state, action) => {
      state.userIsReady = action.payload
    },
    getVersionUpdate: (state, action) => {
      if(action.payload?.pwa?.force){
        state.versionUpdate = action.payload?.website?.force
      }
    },
    handleSockerOrderData: (state, action) => {
      state.orderSocketData = action.payload
    },
  }
})


export const {handleSockerOrderData,getVersionUpdate,addOrderForOderBook, addTicketChat, handleMenuDrop, reloadApiOrder, changeUserIsLogin, changeUserIsReady } = DataSaver.actions;
export default DataSaver.reducer;
