import { CircularProgress, DialogContent, Divider } from '@mui/material'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { digitalName, sideFA, statusFA } from '../../../config/variable'
import moment from 'jalali-moment'
import { zeroFormat } from '../../../helpers/tools'
import { IoMdCloseCircle } from 'react-icons/io'
import { config } from '../../../config/config'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import * as DialogShow from '../../../services/redux/dialog'
import gather from '../../../helpers/gather'
import * as BackdropShow from '../../../services/redux/backdrop'
import { useTranslation } from 'react-i18next'
import CheckVoucher from './CheckVoucher'
import useSnack from '../../../hooks/useSnack'

const DetailVoucherPerfict = ({ data, deposit, dataOrdre = {}, close }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const listNetworkObj = useSelector((state: any) => state.publicApis.listNetworkObj)
  const newCode = data?.code ?? data?.data?.code;
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const checkVoucher = async () => {
    setMyLoading(true);

    let body: any = {
      "coin": data?.coin?._id,
      "network": listNetworkObj["uusd"]?._id ?? 0,
      "code": newCode
    }

    const result = await gather(`${config.api}/v1/voucher/check`).post(body)

    if (result.message === 200) {
      dispatch(DialogShow.show2(<CheckVoucher data={result?.data} myData={data} close={() => dispatch(DialogShow.hide2())} />));
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);

  }

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        {/* <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" /> */}
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw'>
        <div className='body'>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>ثبت درخواست</h4>
              <p>{moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>پردازش سیستم</h4>
              <p>{moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' className='line' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step'>
            <div className={`circlePoint ${data?.status}`}>
              <span></span>
            </div>
            <div className={`textDate ${data?.status}`}>
              <h4 id={`${data?.status}`}>{statusFA[data?.status]}</h4>
              <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
          </div>
          <Divider className='divder' />
          <div className='dec'>
            <p>شناسه درخواست</p>
            <div className='shaba'>
              <CopyToClipboard text={data?.uniqueId} onCopy={() => { (data?.uniqueId) && snackUse.showSaccess("آدرس کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?.uniqueId}</p>
            </div>
          </div>
          <div className='dec'>
            <p>شماره سفارش</p>
            <div className='shaba'>
              <CopyToClipboard text={data?._id} onCopy={() => { data?._id && snackUse.showSaccess("شماره سفارش کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?._id}</p>
            </div>
          </div>
          <div className='dec'>
            <p>وضعیت</p>
            <div className='status confirm'>
              {data?.status == "reject"
                ? <IoMdCloseCircle className='close' size={22} />
                : (data?.status == "confirm" || data?.status == "complete")
                  ? <span className='icon-TickSquare'></span>
                  : <span className='icon-IconInfoCircle'></span>
                // : <LazyLoadImage crossOrigin='anonymous' src={`/icons/${data?.status == "confirm" ? "m-IconTickSquare" : "m-IconInfoCircle"}.svg`} alt="" />
              }
              <p id={`${data?.status}`}>{statusFA[data?.status]}</p>
            </div>
          </div>
          <div className='dec vouBox'>
            <div className='brBox'></div>
            <p>کد ووچر</p>
            <div className='shaba'>
              <CopyToClipboard text={newCode} onCopy={() => { newCode && snackUse.showSaccess(" کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{newCode ?? "در حال آماده سازی"}</p>
            </div>
          </div>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p>سمت</p>
            <p className={deposit ? "colorGreen" : "colorRed"}>{deposit ? "خرید" : "فروش"}</p>
          </div>
          <div className='dec'>
            <p>نوع ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
              <p>{digitalName[data?.coin?.symbol.toLocaleLowerCase()] ?? data?.coin?.symbol?.toUpperCase()}</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار</p>
            <p className='ltr'>{zeroFormat(data?.amount)}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          {data && <>
            <div className='dec'>
              <p>قیمت</p>
              <p className='ltr'>{zeroFormat(data?.price)} {data?.pair}</p>
            </div>
            <div className='dec'>
              <p>کارمزد</p>
              <p className='ltr'>{zeroFormat(data?.fee?.amount)} {data?.coin?.symbol?.toUpperCase()}</p>
            </div>
            <div className='dec'>
              <p>مجموع {deposit ? "خرید" : "فروش"}</p>
              <p className='ltr'>{zeroFormat(data?.total)} {data?.pair}</p>
            </div>
          </>}
          {/* <div className='dec'>
            <p>کد فعالسازی (16 رقمی)</p>
            <div className='shaba'>
              <CopyToClipboard text={newCode?.split("-")[1]} onCopy={() => { newCode?.split("-")[1] && snackUse.showSaccess("آدرس کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{newCode?.split("-")[1] ?? "---"}</p>
            </div>
          </div> */}
          {/* {(deposit && data?.coin?.symbol?.toUpperCase() == "PUSD") &&
            myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button className="finalCheckBtn" onClick={checkVoucher}>استعلام</button>} */}

        </div>
      </DialogContent>
    </>
  )
}

export default DetailVoucherPerfict