import React from 'react'

const WarningMemo = ({ coin, close, text }: { coin: string, close: any, text: string }) => {
  return (
    <div className='warningMemo'>
      <div className='top'>
        <p>توجه!</p>
        <span className='iconClose cPointer' onClick={close} ></span>
      </div>
      <h4 className='text'>هنگام {text} در شبکه {coin} از اکسنوین، حتما تگ را ثبت کنید. در غیر این صورت انتقال دارایی‌تان انجام نمی‌شود و داری‌تان از بین می‌رود.</h4>
      <button className='finalCheckBtn' onClick={close}>تایید</button>
    </div>
  )
}

export default WarningMemo