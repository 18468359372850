import { DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import CustomSelectBoxLabel from '../../../../components/CustomSelectBoxLabel';
import ItemOther from './ItemOther';
import BalanceNumber from './BalanceNumber';
import useSnack from '../../../../hooks/useSnack';

const OtherCoins = ({ close, selectCoin, selectOrder, ListCoin, pairFlag = false, isMarket = false }: any) => {
  const [selectColumn, setSelectColumn]: any = useState({});
  const [list, setList]: any = useState({});
  const [listFix, setListFix]: any = useState({});
  const [filterPair, setFilterPair] = useState("tmn");
  const snackUse = useSnack();

  const handleClick = (o: any, c: any) => {
    if (c?.isActive) {
      selectOrder(o);
      selectCoin(c);
      close()
    }else{
      snackUse.showError("ارز مورد نظر غیرفعال می باشد");
    }
  }

  const hanleSort = (e: any) => {
    setSelectColumn(e);

    let filterList: any = []
    if (e.name == "بیشترین قیمت") {
      filterList = Object.values(listFix)?.sort((a: any, b: any) => b.doolarPrice - a.doolarPrice);
    } else if (e.name == "کمترین قیمت") {
      filterList = Object.values(listFix)?.sort((a: any, b: any) => a.doolarPrice - b.doolarPrice);
    } else {
      filterList = [];
    }

    if (filterList.length > 0) {
      const list = filterList?.reduce((a: any, v: any) => ({ ...a, [pairFlag ? v.pairSymbol : v?.symbol]: v }), {});
      setList(list);
    } else {
      setList(listFix);
    }
  }

  const searchItem = (text: any) => {
    if (_.isEmpty(listFix)) return
    const find = Object.values(listFix).filter((e: any) =>
      e?.name?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.symbol?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.nameFa?.toLowerCase()?.includes(text?.toLowerCase()));

    if (find) {
      const list = find.reduce((a: any, v: any) => ({ ...a, [v?.symbol]: v }), {});
      setList(list);
    }
  }

  const filterByPair = (item: string) => {
    setFilterPair(item);
    const filterList = Object.values(ListCoin).filter((e: any) => e.myPair == item);
    if (filterList) {
      const list = filterList.reduce((a: any, v: any) => ({ ...a, [v?.symbol]: v }), {});
      setList(list)
      setListFix(list)
    }
    setSelectColumn({})
  }


  useEffect(() => {
    if (pairFlag) {
      filterByPair("tmn")
    } else {
      setList(ListCoin)
      setListFix(ListCoin)
    }
  }, [ListCoin])

  return (
    <DialogContent>
      <div className='otherCoinsView'>
        <div className='top'>
          <div>
            <p>موجودی کیف پول  : &nbsp; </p>
            <BalanceNumber />
            {/* <p>{zeroFormat(Object.values(balance).reduce((n, e: any) => n + e.value.tmn, 0), 0, false)} تومان</p> */}
          </div>
          <span className='iconClose cPointer' onClick={close}></span>
        </div>
        <div className='body'>
          <div className='controller'>
            <div className='searchBox'>
              <input onChange={(e) => searchItem(e.target.value)} type="text" placeholder='جستجو در تمامی رمز ارزها' />
              <span className='icon-IconSearchNormal'></span>
            </div>
            <div className='sortBox'>
              <span className='icon-IconCandle'></span>
              <CustomSelectBoxLabel flag={false} element={
                <div className='showBanck'>
                  <h4 className='p-0 letSpacing0' >{_.isEmpty(selectColumn) ? "مرتب سازی بر اساس دسته بندی" : selectColumn?.name}</h4>
                </div>}
                listData={[{ name: "پیشفرض", icon: "" }, { name: "کمترین قیمت", icon: "icon-IconArrowDownGrray" }, { name: "بیشترین قیمت", icon: "icon-IconArrowUpGray" }]}
                label={""} setInputSatet={hanleSort} className={"styleBankSelect sortSelect"} />
            </div>
          </div>
          {pairFlag
            && <div className='selectPair'>
              <p>انتخاب بر اساس جفت ارز :</p>
              <button onClick={() => filterByPair("tmn")} className={`${filterPair == "tmn" ? "active" : ""}`}>تومان</button>
              <button onClick={() => filterByPair("usdt")} className={`${filterPair == "usdt" ? "active" : ""}`}>USDT</button>
            </div>
          }
          <div className="newTable">
            <div className="headT">
              <div className="box">
                <div className="fontBold">نام ارز</div>
                <div className="fontBold">قیمت دلاری</div>
                <div className="fontBold">دارایی در دسترس</div>
                {isMarket
                  ? <div className="fontBold">قیمت (تومان)</div>
                  : <>
                    <div className="fontBold">خرید (تومان)</div>
                    <div className="fontBold">فروش (تومان)</div>
                  </>
                }
                <div>  </div>
              </div>
            </div>
            <div className="bodyT">
              {!_.isNull(list) &&
                Object?.keys(list)?.map((i: any, n) =>
                  <ItemOther key={n} list={list} data={i} onClick={handleClick} isMarket={isMarket} />
                )}
            </div>
          </div>
        </div>
      </div>
    </DialogContent>

  )
}

export default OtherCoins