import React, { memo, useEffect, useState } from 'react';
import CustomInput from '../../../../components/CustomInput';
import { CircularProgress, Slider } from '@mui/material';
import { zeroFormat, removeComma, toEnglishDigits, autoFormatter, toFixed } from '../../../../helpers/tools';
import { useDispatch, useSelector } from 'react-redux';
import * as BackdropShow from '../../../../services/redux/backdrop';
import gather from '../../../../helpers/gather';
import { config } from '../../../../config/config';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useTicker from '../../../../hooks/useTicker';
import { NavLink } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { menu } from '../../../../config/menu';
import * as DialogShow from '../../../../services/redux/dialog';
import NeedLogin from '../../../../components/NeedLogin';
import useSnack from '../../../../hooks/useSnack';
import ConfirmBuySell from '../../dialog/ConfirmBuySell';
import { useLocalStorage } from 'usehooks-ts';

const findSum = (data: any, amount: number, precision: number, isBuy: boolean = true) => {
  if (!data) return
  let sum = 0;
  let price = 0;
  let count = 0;
  for (const e of data) {
    if (amount <= sum) {
      break;
    }
    count++;
    if (!isBuy) {
      sum += e.amount;
    } else {
      sum += (e.amount * e.price);
    }
    price += e.price;

  }
  return toFixed(price / count, precision);
};

const BuySell = ({ ordersBook, flag, coin, base, typePrice, setReload, price, setPrice, amount, setAmount, total, setTotal }:
  { ordersBook: any, flag: boolean, coin: any, base: any, typePrice: any, setReload: any, price: any, setPrice: any, amount: any, setAmount: any, total: any, setTotal: any }) => {
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const profile = useSelector((state: any) => state.user?.profile);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const orderBData = useSelector((state: any) => state.orderBook?.orderBook);
  const ticker = useTicker();
  const coinPriceNow = ticker.price(coin?.pairSymbol);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [amountError, setAmountError] = useState(false);
  const [stopPrice, setStopPrice] = useState(0);
  // const [feeNumber, setFeeNumber] = useState(0);
  const [stopError, setStopError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [valueSlider, setValueSlider] = useState(0);
  const [limitBuy, setLimitBuy] = useState(false);
  const [limitSell, setLimitSell] = useState(false);
  const snackUse = useSnack();
  const [confiemOrder] = useLocalStorage('confiemOrderPro', "false");
  const [myLoading, setMyLoading] = useState(false);
  const [bestNum, setBestNum] = useState(ordersBook);
  const marks = [
    { value: 0, },
    { value: 25, },
    { value: 50, },
    { value: 75, },
    { value: 100 },
  ];

  const resetState = () => {
    setPrice(typePrice == "market" ? ordersBook : 0);
    setAmount(0);
    setValueSlider(0)
    setStopPrice(0)
    setTotal(0);
    setLimitBuy(false);
  }

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);
    const balance = mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free;

    const percent = Number(newValue);

    const newAmount = balance * (percent / 100) || 0;

    let newPrice = 0
    if (typePrice == "market") {
      newPrice = newAmount > 0 ? findSum(orderBData[flag ? "sell" : "buy"], newAmount, coin?.pair[base]?.precision, flag) : ordersBook
      setPrice(toFixed(newPrice, coin?.pair[base]?.precision))
    } else {
      newPrice = price > 0 ? price : ordersBook;
      setPrice(newPrice);
    }

    const final = flag ? newAmount / newPrice : newAmount;
    const myPercent = Number((0.0005 * final) / 100);
    if (flag) {
      setAmount(toFixed((final - myPercent), coin?.decimal));
    } else {
      setAmount(toFixed(final, coin?.decimal));
    }
    setTotal(toFixed((final * newPrice), coin?.pair[base]?.precision));
  }

  const changeAmount = async (e: any) => {
    let newPrice = 0
    if (typePrice == "market") {
      newPrice = e > 0 ? findSum(orderBData[flag ? "sell" : "buy"], e, coin?.pair[base]?.precision) : ordersBook
      setPrice(toFixed(newPrice, coin?.pair[base]?.precision))
    } else {
      newPrice = price
    }

    setAmount(toFixed(e, coin?.decimal));
    setTotal(toFixed(e * newPrice, coin?.pair[base]?.precision))

    const total = flag
      ? Number(removeComma(e)) * Number(newPrice ?? 0)
      : Number(removeComma(e))

    const balance = mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free;
    const pers = ((Number(removeComma(total)) / balance) * 100);
    setValueSlider(pers > 100 ? 100 : pers);

  }

  const handleTotal = () => {
    const fee = Number(profile?.plane?.level?.fee?.[coin?.myPair ?? "tmn"]?.[flag ? "buy" : "sell"]);
    if (flag) {
      const f = fee > 0 ? ((fee / 100) * amount) : 0
      const t = Number(amount - f);
      return { t, f }
    } else {
      const f = fee > 0 ? Number((fee / 100) * (amount * price)) : 0
      const t = (Number(amount * price) - f)
      return { t, f }
    }
  }

  const handleClickBalance = (balanceUser: any) => {
    if (balanceUser <= 0 || balanceUser == undefined) {
      return
    }

    setValueSlider(100);
    let a = 0;

    let newPrice = 0
    if (typePrice == "market") {
      newPrice = balanceUser > 0 ? findSum(orderBData[flag ? "sell" : "buy"], balanceUser, coin?.pair[base]?.precision, flag) : ordersBook;
      setPrice(toFixed(newPrice, coin?.pair[base]?.precision));
    } else {
      newPrice = price > 0 ? price : ordersBook;
      setPrice(newPrice);
    }


    if (flag) {
      let newAmount = Number(balanceUser / newPrice);
      if (newAmount) {
        const myPercent = Number((0.0005 * newAmount) / 100);
        setAmount(toFixed((newAmount - myPercent), coin?.decimal));
        a = newAmount - myPercent;
      } else {
        setAmount(0);
        a = 0
      }
    } else {
      setAmount(toFixed(balanceUser, coin?.decimal));
      a = balanceUser
    }


    setTimeout(() => {
      setTotal(toFixed(a * newPrice, coin?.pair[base]?.precision))
    }, 150);
  }

  const handleTotalChange = (e: any) => {
    setTotal(e)
    if (Number(e) > 0) {
      let newPrice;
      if (!price || price <= 0) {
        newPrice = coinPriceNow;
        setPrice(toFixed(newPrice, coin?.pair[base]?.precision));
      } else {
        newPrice = price
      }
      const newAmount = Number(e) / Number(newPrice)
      setAmount(toFixed(newAmount, coin?.decimal))
    } else {
      setAmount(0)
    }
  }

  const handleOffer = () => {
    if (typePrice != "market") {
      const p = bestNum || 0
      setPrice(toFixed(p, coin?.pair[base]?.precision));
      setTotal(toFixed(amount * p, coin?.pair[base]?.precision));
    }
  }

  const confirmSend = () => {
    const max = (coinPriceNow + ((5 * coinPriceNow) / 100))
    const min = (coinPriceNow - ((5 * coinPriceNow) / 100));

    if (typePrice == "stop" && stopPrice <= 0) {
      snackUse.showError("قیمت توقف صحیح نمی باشد");
      setStopError(true)
      setTimeout(() => { setStopError(false) }, 300);
      return
    }

    if (typePrice == "stop" && (flag && stopPrice <= coinPriceNow) || (!flag && stopPrice >= coinPriceNow)) {
      snackUse.showError("قیمت توقف معتبر نیست");
      setStopError(true)
      setTimeout(() => { setStopError(false) }, 300);
      return
    }

    // if (Number(coin?.pair[base?.toLocaleLowerCase()]?.min) > Number(toEnglishDigits(amount?.toString()))) {
    if (Number(coin?.pair[base?.toLocaleLowerCase()]?.min) > Number(toEnglishDigits(total?.toString()))) {
      if (flag) {
        setLimitBuy(true)
      } else {
        setLimitSell(true);
      }
      snackUse.showError("حداقل میزان سفارش را بررسی کنید");
      return
    }

    if (_.isEmpty(coin)) {
      snackUse.showError("لطفا یک ارز را انتخاب کنید");
      return
    }

    if (price <= 0) {
      snackUse.showError("قیمت را وارد کنید");
      setPriceError(true)
      setTimeout(() => { setPriceError(false) }, 300);
      return
    }

    if (!(price > min && price < max)) {
      snackUse.showError("قیمت معتبر نیست");
      setPriceError(true)
      setTimeout(() => { setPriceError(false) }, 300);
      return
    }

    if (amount <= 0) {
      snackUse.showError("مقدار را وارد کنید");
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 300);
      return
    }

    setLimitSell(false);
    setLimitBuy(false);

    if (confiemOrder == "true") {
      send()
    } else {
      const data = {
        "coin": coin,
        "side": flag ? "buy" : "sell",
        "price": Number(toEnglishDigits(price?.toString())),
        "amount": Number(toEnglishDigits(amount?.toString())),
        "trade": typePrice,
        "total": Number(toEnglishDigits(total?.toString())),
        "fee": handleTotal().f,
        "totalFee": handleTotal().t,
        "stop": typePrice == "stop" ? Number(toEnglishDigits(stopPrice?.toString())) : null,
        "base": base
      }
      dispatch(DialogShow.show(<ConfirmBuySell data={data} send={send} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const send = async () => {
    setMyLoading(true);

    const body = {
      "coin": coin._id,
      "pair": base,
      "side": flag ? "buy" : "sell",
      "price": Number(toEnglishDigits(price?.toString())),
      "amount": Number(toEnglishDigits(amount?.toString())),
      "trade": typePrice,
      "stop": typePrice == "stop" ? Number(toEnglishDigits(stopPrice?.toString())) : null
    }

    const result = await gather(`${config.api}/v1/order`).post(body);
    dispatch(DialogShow.hide());
    if (result.message === 200) {
      dispatch(DialogShow.hide());
      setReload(new Date().getTime().toString());
      snackUse.showSaccess("سفارش شما با موفقیت ثبت شد");
      resetState();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  useEffect(() => {
    resetState();
  }, [coin, typePrice])

  useEffect(() => {
    setBestNum(ordersBook);
    if (typePrice == "market" && amount == 0) {
      setPrice(ordersBook);
    }
  }, [ordersBook])


  return (
    <div className='boxBS'>
      <div className='titleBS'>
        <h3>{flag ? "خرید" : "فروش"} <b className={coin?.symbol?.length > 5 ? "font-14" : ""}>{coin?.symbol.toUpperCase()}</b></h3>
        {userIsLogin
          && <div className='cPointer'>
            <NavLink
              to={(flag ? base : coin?.symbol) == "tmn"
                ? menu.financial.childs.depositAll.childs.depositCash.childs.default.url
                : menu.financial.childs.depositAll.childs.depositCoin.url + "/" + (flag ? base?.toUpperCase() : coin?.symbol?.toUpperCase())}
              className='cPointer flexCenter'>
              <IoIosAddCircleOutline />
            </NavLink>
            <p onClick={() => handleClickBalance(mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free)}>
              {autoFormatter(mainWalletObj[flag ? coin?.myPair : coin?.symbol]?.free)} {flag ? base?.toUpperCase() : coin?.symbol.toUpperCase()?.slice(0, 4)}
            </p>
            <span className='icon-Wallt15'></span>
          </div>
        }
      </div>
      {typePrice == "stop"
        && <CustomInput error={stopError} inputState={stopPrice} setInputSatet={(e: any) => setStopPrice(e)} label={""}
          tooltipTitle={`قیمت فعال شدن حد ضرر در سفارش ${flag ? 'خرید باید بیشتر' : 'فروش باید کمتر'} از قیمت فعلی بازار باشد`}
          classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
          endEle={<p className='colorGray '>قیمت توقف</p>} decimalScale={coin?.pair[base]?.precision} className={"ltr mb-15 "} placeholder={""} />}
      {
        typePrice == "market"
          ? <CustomInput readOnly={true} error={false} inputState={price} setInputSatet={() => { }} label={""}
            classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
            endEle={<p className='colorGray '>قیمت</p>} decimalScale={coin?.pair[base]?.precision} className={"ltr mb-15 "} placeholder={""} />

          : <CustomInput error={priceError} inputState={price} setInputSatet={(e: any) => { setPrice(e); setTotal(toFixed(amount * e, coin?.pair[base]?.precision)) }} label={""}
            classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
            endEle={<p className='colorGray '>قیمت</p>} decimalScale={coin?.pair[base]?.precision} className={"ltr mb-15 "} placeholder={""} />
      }

      <CustomInput error={amountError} inputState={amount} decimalScale={coin?.decimal} setInputSatet={changeAmount}
        label={""} classEnd={'styleP'} classStart={"brNone styleP"} startEle={<p className={`colorGray ${coin?.symbol?.length > 5 ? "font-12" : ""}`}>{coin?.symbol?.length > 5 ? coin?.symbol?.slice(0, 4)?.toUpperCase() : coin?.symbol.toUpperCase()}</p>}
        endEle={<p className='colorGray '>مقدار</p>} className={"ltr mb-12"} placeholder={""} />

      <div className='bestAmount'>
        <p>بهترین پیشنهاد {flag ? 'خرید' : 'فروش'}: </p>
        <p className='cPointer' onClick={handleOffer}><b>{coin?.myPair?.toUpperCase()}</b>{zeroFormat(bestNum)}</p>
      </div>
      <div className='sliderBS'>
        <Slider
          onChange={handleSliderBar}
          aria-label="SilderBar"
          marks={marks}
          step={25}
          value={typeof valueSlider === 'number' ? valueSlider > 100 ? 0 : valueSlider : 0}
          sx={{
            color: 'var(--color-sliderBr)',
            '& .MuiSlider-track': {
              border: `3px solid ${flag ? "#22c58b" : "#ff6666"}`,
            },
            '& .MuiSlider-thumb': {
              width: 19,
              height: 19,
              backgroundColor: '#fff',
              border: `4px solid ${flag ? "#22c58b" : "#ff6666"}`,
              '&::before': {
                boxShadow: '0px 7.45px 14.91px -7.45px #0f0f0f33',
              },
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: 'none',
              },
            },
            '& .MuiSlider-mark': {
              backgroundColor: "#e0e0e0",
              width: 8,
              height: 8,
              borderRadius: 50,
            },
            '& .MuiSlider-markActive': {
              backgroundColor: `${flag ? "#22c58b" : "#ff6666"}`,
            }
          }}
        />
      </div>
      <CustomInput id={"totalAmount"} decimalScale={coin?.pair[base]?.precision} inputState={total} setInputSatet={handleTotalChange} label={""}
        classEnd={'brNone styleP'} classStart={"brNone styleP"}
        startEle={<p className='colorGray '>{base?.toUpperCase()}</p>}
        endEle={<p className='colorGray '>مبلغ کل</p>} className={"ltr mb-15 "} placeholder={""} />
      {flag
        ? <div className={`priceLimit ${limitBuy ? "" : "opacity0"}`}>
          <p>حداقل خرید </p>
          <h5>{zeroFormat(Number(coin?.pair[base?.toLocaleLowerCase()]?.min))}&nbsp; {base?.toUpperCase()}</h5>
        </div>
        : <div className={`priceLimit ${limitSell ? "" : "opacity0"}`}>
          <p>حداقل فروش </p>
          <h5>{zeroFormat(Number(coin?.pair[base?.toLocaleLowerCase()]?.min))}&nbsp; {base?.toUpperCase()}</h5>
        </div>
      }
      {userIsLogin
        ? myLoading
          ? <div className={`loadingCircular ${flag ? "" : "sell"}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
          : <button onClick={confirmSend}
            className={`submitBS ${flag ? "" : "sell"}`}>{flag ? "خرید" : "فروش"} {coin?.symbol.toUpperCase()}
          </button>
        : <button onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}
          className={`submitBS ${flag ? "" : "sell"}`}>ثبت نام - ورود
        </button>
      }
      <div className='priceFee'>
        <p>کارمزد</p>
        <h5>{zeroFormat(toFixed(handleTotal().f, flag
          ? coin?.decimal
          : base == "tmn" ? 0 : coin?.pair[base]?.precision)
          , flag
            ? coin?.decimal
            : base == "tmn" ? 0 : coin?.pair[base]?.precision, false)}
          &nbsp; {flag ? coin?.symbol?.toUpperCase().slice(0, 4) : base?.toUpperCase()}</h5>
      </div>
      <div className='priceFee'>
        <p>دریافتی شما</p>
        <h5>{zeroFormat(toFixed(handleTotal().t, flag
          ? coin?.decimal
          : base == "tmn" ? 0 : coin?.pair[base]?.precision)
          , flag
            ? coin?.decimal
            : base == "tmn" ? 0 : coin?.pair[base]?.precision, false)}
          &nbsp; {flag ? coin?.symbol?.toUpperCase().slice(0, 4) : base?.toUpperCase()}</h5>
      </div>
    </div>
  )
}

export default memo(BuySell) 