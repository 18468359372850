import React, { useEffect, useState } from 'react';
import NavChangeWithdraw from './items/NavChangeWithdraw';
import CustomSelectBox2 from '../../components/CustomSelectBox2';
import { useDispatch, useSelector } from 'react-redux';
import History from './items/History';
import _ from 'lodash';
import CustomInputText from '../../components/CustomInputText';
import CustomInput from '../../components/CustomInput';
import { Divider } from '@mui/material';
import { autoFormatter, toFixed, zeroFormat } from '../../helpers/tools';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { useParams } from 'react-router-dom';
import CustomSelectBoxSearch from '../../components/CustomSelectBoxSearch';
import * as DialogShow from '../../services/redux/dialog';
import ConfirmWithdraw from './dialogs/ConfirmWithdraw';
import ListWithdraw from './dialogs/ListWithdraw';
import WarningMemo from '../depositAll/dialogs/WarningMemo';
import { menu } from '../../config/menu';
import useSnack from '../../hooks/useSnack';
import Footer from '../../components/Footer';
import NeedUpgrade from '../../components/NeedUpgrade';
import useTicker from '../../hooks/useTicker';

const WithdrawCoin = () => {
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const listCoinObjByID = useSelector((state: any) => state.publicApis?.listCoinObjByID);
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const profile: any = useSelector((state: any) => state.user?.profile);
  const dispatch = useDispatch<any>();
  const ticker = useTicker();
  const snackUse = useSnack();
  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [coinSelect, setCoinSelect]: any = useState({});
  const [networkSelect, setNetworkSelect]: any = useState({});
  const [coinSelectError, setCoinSelectError] = useState(false);
  const [networkSelectError, setNetworkSelectError] = useState(false);
  const [reload, setReload] = useState(new Date());
  const { coinParam } = useParams();
  const [reset, setReset] = useState('');
  const [chooseItem, setChooseItem]: any = useState({});
  const [tagMemo, setTagMemo] = useState('');
  const [needTagMemo, setNeedTagMemo] = useState(false);
  const Remainder = profile.plane.level.withdraw.coin.daily - profile.limit.coin.daily;

  const hanleSelect = (e: any) => {
    setCoinSelect(e);
    setNetworkSelect({});
    setAmount(0);
    setAddress('');
    setNeedTagMemo(false);
    setTagMemo("");
  }

  const justEnglish = (e: any) => {
    // const re = /^[A-Za-z0-9-_]+$/;
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setAddress(e);
    } else {
      snackUse.showError("فقط حروف لاتین برای آدرس کیف پول وارد کنید");
    }
  };

  const justNum = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setTagMemo(e);
    } else {
      snackUse.showError("فقط اعداد و حروف لاتین برای آدرس ممو وارد کنید");
    }
  };

  const handlePercent = (count: any, total: any) => {
    if (count && total) {
      return toFixed(Number(Number(count * 100) / total), 1)
    }
    return 0
  }

  const hanleSelectNetwork = (e: any) => {
    setNeedTagMemo(false);
    setAddress("");
    setAddressError(false);
    setTagMemo("");
    setNetworkSelect(e);

    if (e?.network?.memo?.isForce || e?.network?.memo?.isNeed) {
      setNeedTagMemo(true);
      dispatch(DialogShow.show(<WarningMemo text="برداشت" coin={e?.network?.name?.toUpperCase()} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const pastWallet = async () => {
    const text = await navigator.clipboard.readText();
    justEnglish(text)
    // setAddress(text);
  }

  const handleMaxWithdraw = () => {
    const daily = Number(profile?.limit?.coin?.daily);
    const monthly = Number(profile?.limit?.coin?.monthly);
    const maxMonthly = Number(profile?.plane?.level?.withdraw?.coin?.monthly);
    const maxDaily = Number(profile?.plane?.level?.withdraw?.coin?.daily);

    const calcDaily = Number(maxDaily - daily) ?? 0;
    const calcMonthly = Number(maxMonthly - monthly) ?? 0;

    return { calcDaily, calcMonthly }
  }

  const send = async () => {

    if (_.isEmpty(coinSelect)) {
      snackUse.showError("لطفا ارز خود را وارد کنید");
      setCoinSelectError(true)
      setTimeout(() => { setCoinSelectError(false) }, 3000)
      return;
    }

    if (_.isEmpty(networkSelect)) {
      snackUse.showError("لطفا شبکه انتقال را انتخاب کنید");
      setNetworkSelectError(true)
      setTimeout(() => { setNetworkSelectError(false) }, 3000)
      return;
    }

    if (!coinSelect?.isActive) {
      snackUse.showError("ارز مورد نظر غیرفعال می باشد");
      return;
    }
    if (!networkSelect?.isActive) {
      snackUse.showError("شبکه مورد نظر غیرفعال می باشد");
      return;
    }


    if (coinSelect.symbol?.toLocaleLowerCase() != "usdt" && !ticker.price(coinSelect.symbol + 'usdt')) {
      snackUse.showError("دردسترس نیست");
      return
    }

    if (profile?.plane?.level?.index === 0 || _.isNull(profile?.plane?.level?.index)) {
      dispatch(DialogShow.show2(<NeedUpgrade />))
      return
    }



    if (!networkSelect?.withdraw?.isActive) {
      snackUse.showError("این ارز در این شبکه قابلیت برداشت ندارد");
      return
    }

    if (address.length < 20) {
      snackUse.showError("آدرس مقصد را بررسی کنید");
      setAddressError(true)
      setTimeout(() => { setAddressError(false) }, 3000)
      return;
    }

    if (networkSelect?.network?.regex &&
      !new RegExp(networkSelect?.network?.regex).test(address)) {
      snackUse.showError("آدرس مقصد را معتبر نیست");
      setAddressError(true)
      setTimeout(() => { setAddressError(false) }, 3000)
      return;
    }

    if (address.length < 20) {
      snackUse.showError("آدرس مقصد را بررسی کنید");
      setAddressError(true)
      setTimeout(() => { setAddressError(false) }, 3000)
      return;
    }

    if (amount < 0 || amount === 0 || amount < networkSelect?.withdraw?.min) {
      snackUse.showError("لطفا حداقل مقدار برداشت را رعایت کنید");
      setAmountError(true)
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    if ((amount * ticker.price(coinSelect.symbol + 'usdt')) > handleMaxWithdraw().calcDaily || (amount * ticker.price(coinSelect.symbol + 'usdt')) > handleMaxWithdraw().calcMonthly) {
      snackUse.showError(`مقدار برداشت درخواست شده بیش از سقف مجاز است`);
      setAmountError(true);
      setTimeout(() => { setAmountError(false) }, 3000)
      return;
    }

    if (networkSelect?.network?.memo?.isForce && tagMemo?.trim().length <= 0) {
      snackUse.showError(" تگ / ممو را وارد کنید");
      return;
    }

    const data: any = {
      coin: coinSelect,
      network: networkSelect,
      receiver: address,
      amount: Number(amount),
      final: Number(amount) - Number(networkSelect?.withdraw?.fee) > 0 ? zeroFormat(Number(amount) - Number(networkSelect?.withdraw?.fee)) : 0
    }

    if (networkSelect?.network?.memo?.isForce || networkSelect?.network?.memo?.isNeed) {
      data["memo"] = tagMemo
    }

    dispatch(DialogShow.show(<ConfirmWithdraw
      resetAll={resetAll} profile={profile}
      setReload={setReload}
      data={data} close={() => dispatch(DialogShow.hide())} />))
  }

  const resetAll = () => {
    setReset(new Date().getTime().toString())
    setCoinSelect({});
    setNetworkSelect({});
    setAmount(0);
    setAddress('');
    setNeedTagMemo(false);
  }

  useEffect(() => {
    document.title = menu.financial.childs.withdrawAll.childs.withdrawCoin.title;

    setTimeout(async () => {
      if (coinParam) {
        setCoinSelect(listCoinObj[coinParam?.toLocaleLowerCase()])
      }
    }, 10);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(chooseItem)) {

      setCoinSelect(listCoinObjByID[chooseItem?.coin?._id]);
      // setAddress(chooseItem?.address);
      justEnglish(chooseItem?.address)

      const find = listCoinObjByID[chooseItem?.coin?._id]?.networks?.filter((e: any) =>
        e?.network?._id?.toLowerCase() == chooseItem?.network?._id);

      if (find) {
        setNetworkSelect(find[0]);
        if (chooseItem?.memo?.trim().length > 0) {
          setNeedTagMemo(true);
          justNum(chooseItem?.memo);
        }
      }
    }
  }, [chooseItem]);

  console.log(coinSelect);


  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox'>
                <NavChangeWithdraw />
                <div className='stepDepWith'>
                  <p><span>1</span></p>
                  <h5>انتخاب ارز دیجیتال</h5>
                </div>
                <CustomSelectBoxSearch classNamePopup={"listCoinPopupV2"}
                  error={coinSelectError} listData={listCoinObj ?? []} setInputSatet={hanleSelect} defalutText={coinSelect?.nameFa ?? ""}
                  defalteImg={coinSelect ?? {}} reset={reset} className={" mb-28"} />
                <div className='stepDepWith'>
                  <p><span>2</span></p>
                  <h5>نوع شبکه</h5>
                </div>
                <CustomSelectBox2 flag={false} showFee={true} element={
                  <div className='showCoin'>
                    <h4 >{networkSelect?.network?.name ?? <b>نوع شبکه را انتخاب کنید</b>}</h4>
                  </div>} typeFee={"withdraw"}
                  error={networkSelectError} symbol={coinSelect?.symbol?.toUpperCase() ?? ''} listData={coinSelect?.networks ?? []} setInputSatet={hanleSelectNetwork} className={" mb-28"} />

                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>3</span></p>
                    <h5>آدرس مقصد</h5>
                  </div>
                  <div className="addCard gap-2" onClick={() =>
                    dispatch(DialogShow.show(<ListWithdraw setChooseItem={setChooseItem} close={() => dispatch(DialogShow.hide())} />))
                  }>
                    <span className='icon_addressBook'></span>
                    <p>انتخاب از لیست</p>
                  </div>
                </div>
                <CustomInputText label={''} inputState={address} setInputSatet={justEnglish} className={"py-12 mb-10"}
                  error={addressError} classEnd={"brNone"} placeholder={"آدرس مقصد را وارد کنید"}
                  endEle={<span onClick={pastWallet} className='icon-IconPaste2 cPointer'></span>} />
                <div className='limitDepositBox mb-28'>
                  <p className='colorRed'>وارد کردن آدرس نادرست ممکن است منجر به از دست رفتن دارایی شما شود.</p>
                </div>
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>4</span></p>
                    <h5>مقدار برداشت</h5>
                  </div>
                  <div className="addCard" onClick={() => setAmount(Number(mainWalletObj[coinSelect?.symbol]?.free))}>
                    <p>حداکثر قابل برداشت <b>{zeroFormat(mainWalletObj[coinSelect?.symbol]?.free)}</b> {coinSelect?.symbol?.toUpperCase()}</p>
                  </div>
                </div>
                <CustomInput error={amountError} decimalScale={coinSelect?.decimal ?? 0} inputState={amount} setInputSatet={setAmount} label={""} classStart={""} startEle={<p className='colorGray '>{coinSelect?.symbol?.toUpperCase() ?? "---"}</p>} endEle={""} className={"ltr mb-28"} placeholder={"0.00"} />
                {needTagMemo
                  && <>
                    <div className='stepDepWith'>
                      <p><span>5</span></p>
                      <h5>تگ / memo</h5>
                    </div>
                    <CustomInputText label={''} inputState={tagMemo} setInputSatet={justNum} className={"py-12"}
                      classEnd={"brNone"} placeholder={"تگ / memo"} />
                  </>
                }

                <Divider className='divider' />
                <div className='feeFinalAmount'>
                  <div>
                    <p>کارمزد شبکه</p>
                    {/* <span className='icon-IconInfoCircle'></span> */}
                  </div>
                  {!_.isEmpty(networkSelect) && <p><b>{coinSelect?.symbol?.toUpperCase()} </b> {zeroFormat(networkSelect?.withdraw?.fee)} </p>}
                </div>
                <div className='feeFinalAmount'>
                  <p>حداقل مقدار برداشت</p>
                  {!_.isEmpty(networkSelect) && <p><b>{coinSelect?.symbol?.toUpperCase()} </b> {zeroFormat(networkSelect?.withdraw?.min)} </p>}
                </div>
                <div className='feeFinalAmount'>
                  <p> مقدار دلاری</p>
                  <p>{zeroFormat(Number(amount * ticker.price(coinSelect.symbol + 'usdt')),0,false)} <b>دلار</b></p>
                </div>
                <div className='feeFinalAmount'>
                  <p> مقدار نهایی</p>
                  <p> {Number(amount) - Number(networkSelect?.withdraw?.fee) > 0 ? zeroFormat(Number(amount) - Number(networkSelect?.withdraw?.fee)) : 0} <b>{coinSelect?.symbol?.toUpperCase()}</b></p>
                </div>
                <button className='submit' onClick={(send)}>درخواست برداشت</button>
                <div className='limitWithdraw'>
                  <div className='dailyText'>
                    <p className='dotGreen'>سقف برداشت شما (روزانه):</p>
                    <h5>{zeroFormat(Number(profile?.plane?.level?.withdraw?.coin?.daily))} دلار</h5>
                  </div>
                  <div className='dailyText'>
                    <p>برداشت شده امروز :</p>
                    <h5>{zeroFormat(toFixed(Number(profile?.limit?.coin?.daily), 0), 0, false)} دلار</h5>
                  </div>
                  <div className='remainingDailyText'>
                    <div className="text">
                      <h5 className="fontYekanBakh">{handlePercent(profile?.limit?.coin?.daily, profile?.plane?.level?.withdraw?.coin?.daily)}٪ سقف روزانه استفاده شده</h5>
                      <div className="boxDetailMonthly">
                        <span className='icon-IconInfoCircle'></span>
                        <div className="monthly">
                          <div className='dailyText mb-15'>
                            <p className='dotGreen'>سقف برداشت شما (ماهانه):</p>
                            <h5>{zeroFormat(Number(profile?.plane?.level?.withdraw?.coin?.monthly))} دلار</h5>
                          </div>
                          <div className='dailyText'>
                            <p>برداشت ماهانه :</p>
                            <h5>{zeroFormat(toFixed(Number(profile?.limit?.coin?.monthly), 0), 0, false)} دلار</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='allGreen'>
                      <div className='useRed' style={{ width: `${handlePercent(profile?.limit?.coin?.daily, profile?.plane?.level?.withdraw?.coin?.daily)}%` }}></div>
                    </div>
                    <div className='remainderMaxWithdraw'>
                      <span className='t1'>باقی مانده سقف روزانه :</span>
                      <span className='t2'>{autoFormatter(Remainder)} دلار</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='detailBox'>
                <img className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                <div className='infoText'>
                  <h5><b>نکات</b> مهم</h5>
                  <p>رمزارز و نوع شبکه را با دقت انتخاب کنید. </p>
                  <p>آدرس کیف پول مقصد و مقدار رمزارز را با دقت وارد کنید.</p>
                  <p>با توجه به نوع شبکه ، در صورتی که تگ/ MEMO الزامی است ، آن را به درستی وارد کنید.</p>
                  <p>کارمزد شبکه از مقدار برداشت درخواستی شما کسر خواهد شد ، بنابراین در وارد کردن مقدار برداشت خود دقت کنید. </p>
                  <p>هنگام برداشت رمزارزی ، به <b>سقف برداشت روزانه/ماهانه</b> دلاری خود دقت کنید</p>
                </div>
                <div className='infoText pb-30'>
                  <h5><b>راهنما</b></h5>
                  <p>در این صفحه آدرس واریزی کیف پول شامل آدرس و یا در صورت لزوم تگ/ ممو و شبکه واریزی دلخواه خود را انتخاب و کپی کنید.</p>
                  <p>آدرس و یا در صورت لزوم تگ/ممو واریزی کپی شده را در صفحه‌ی مربوط به برداشت (مقصد) وارد کنید.</p>
                </div>
              </div>
            </div>
            <History flag={false} reload={reload} setReload={setReload} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default WithdrawCoin