import { LazyLoadImage } from "react-lazy-load-image-component"
import { config } from "../config/config"

const ListCoinArrayPopup = ({ className, listData, onClick }: any) => {

  return (
    <div className={`listCoinPopup ${className}`}>
      {listData?.filter((e: any) => e?.isActive==true)?.map((i: any, n: any) =>
        <div key={n} className="item" onClick={() => onClick(i)}>
          <div className="name">
            <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${i?.symbol?.toUpperCase()}.png`} alt="" />
            <p>{i?.nameFa}</p>
            <p>{i?.symbol?.toUpperCase()}</p>
          </div>
          <p>{i?.name}</p>
        </div>
      )}
    </div>
  )
}

export default ListCoinArrayPopup