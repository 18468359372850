import moment from 'jalali-moment'
import React, { useState } from 'react'

const ItemNotifcation = ({ data }: any) => {

  const [show, setShow] = useState(false)

  return (
    <div className={`item ${!data?.isRead && "active"}`} onClick={() => setShow(!show)}>
      <div>
        {/* <p >{moment(i?.createdAt).startOf('day').fromNow()}</p> */}
        <p>{moment(data?.createdAt).locale("fa").fromNow()}</p>
        {/* <p>{moment(1714147297067).locale("fa").calendar()}</p> */}
        {/* <p className="text">{i?.title ?? "---"}</p> */}
      </div>
      {show
        ? <p className="my-5">{data?.text}</p>
        : <p className="my-5">{data?.text?.length > 50
          ? data?.text?.slice(0, 50) + ". . ." : data?.text} </p>
      }
    </div>
  )
}

export default ItemNotifcation