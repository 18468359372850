import { useEffect, useState } from 'react';
import InputTextField from './items/InputTextField';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'jalali-moment';
import * as DialogShow from '../../services/redux/dialog';
import Warning from './dialog/Warning';
import AddEmail from './dialog/AddEmail';
import Navbar from '../../components/navbar/Navbar';
import Header from '../../components/header/Header';
import { useNavigate } from 'react-router-dom';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import WarningEmail from './dialog/WarningEmail';
import QustionAddOrEditEmail from './dialog/QustionAddOrEditEmail';

const AccountUser = () => {
  const dispatch = useDispatch<any>()
  const profile = useSelector((state: any) => state.user.profile);
  const [name, setName]: any = useState({ value: `${profile?.name + " " + profile?.family}` || '', error: false });
  const [nationalCode, setNationalCode]: any = useState({ value: profile?.nationalCode ?? '', error: false });
  const [birthDay, setBirthDay]: any = useState({ value: profile?.birthDate ? profile?.birthDate.startsWith("13") ? profile?.birthDate : moment.from(profile?.birthDate, 'YYYY-MM-DD').locale("fa").format('YYYY-MM-DD') : '', error: false });
  const [email, setEmail]: any = useState({ value: profile?.email ?? '', error: false });
  const [mobile, setMobile]: any = useState({ value: profile?.mobile ?? '', error: false });
  const navigate = useNavigate();



  const handelAddEditEmail = async () => {
    if (profile?.verify?.email) {
      dispatch(DialogShow.show(<WarningEmail oldEmail={profile?.email} />))
    } else if (profile?.email) {
      dispatch(DialogShow.show(<QustionAddOrEditEmail value={profile?.email} />))
    } else {
      dispatch(DialogShow.show(<AddEmail />))
    }
  }

  useEffect(() => {
    setName({ value: `${profile?.name + " " + profile?.family}` || '', error: false });
    setNationalCode({ value: profile?.nationalCode ?? '', error: false });
    setBirthDay({ value: profile?.birthDate ?profile?.birthDate.startsWith("13")?profile?.birthDate: moment.from(profile?.birthDate, 'YYYY-MM-DD').locale("fa").format('YYYY-MM-DD') : '', error: false });
    setEmail({ value: profile?.email ?? '', error: false });
    setMobile({ value: profile?.mobile ?? '', error: false });

  }, [profile])

  useEffect(() => {
    document.title = menu.setting.childs.accountUser.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='accountUser'>
            <div className='body'>
              <div className='box'>
                <h3>اطلاعات شخصی</h3>
                <InputTextField label="نام و نام خانوادگی" readOnly={true} type="text" placeholder="وارد کنید" value={name.value ? name.value == "null null" ? "---" : name.value : "---"} onChange={(e: any) => setName((s: any) => ({ ...s, value: e.target.value }))} icon="icon-userprofile" />
                <InputTextField label="کدملی" readOnly={true} type="number" placeholder="وارد کنید" value={nationalCode.value ? nationalCode.value : "---"} onChange={(e: any) => setNationalCode((s: any) => ({ ...s, value: e.target.value }))} icon="icon-kartmelli" />
                <InputTextField label="تاریخ تولد" readOnly={true} type="text" placeholder="وارد کنید" value={birthDay.value ? birthDay.value : "---"} onChange={(e: any) => setBirthDay((s: any) => ({ ...s, value: e.target.value }))} icon="icon-userprofile" />
              </div>
              <div className='box'>
                <h3>اطلاعات تماس</h3>
                <InputTextField
                  value={email.value} readOnly={true} label="پست الکترونیک" confirm={true}
                  confirmText={profile?.verify?.email ? "confirm" : "pending"} edit={true} type="email"
                  placeholder="وارد کنید" icon="icon-emailLogin" handleEdit={handelAddEditEmail}
                  onChange={(e: any) => setEmail((s: any) => ({ ...s, value: e.target.value }))} />

                <InputTextField readOnly={true} label="شماره تماس" confirm={true} confirmText={profile?.verify?.mobile ? "confirm" : "pending"}
                  edit={true} type="tel" placeholder="وارد کنید" icon="icon-kartmelli"
                  handleEdit={() => dispatch(DialogShow.show(<Warning oldMobile={profile?.mobile} />))}
                  value={mobile.value} onChange={(e: any) => setMobile((s: any) => ({ ...s, value: e.target.value }))} />
              </div>
            </div>
            <div className='boxButtonImg'>
              <div className='right'>
                <img crossOrigin='anonymous' src="/icons/growth2.svg" alt="" />
                <div>
                  <h5>ارتقا حساب کاربری</h5>
                  <p>در صورتی که به سقف برداشت روزانه/ماهانه بیشتر نیاز دارید ، از بخش ارتقا حساب کاربری ، درخواست خود را ارسال کنید.</p>
                </div>
              </div>
              <div className='left'>
                <button className="finalCheckBtn" onClick={() => navigate(menu.upgradeLevel.url)}>ارتقا حساب کاربری</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AccountUser