import { LazyLoadImage } from "react-lazy-load-image-component"
import { config } from "../config/config"
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material";
import _ from "lodash";

const ListTextCheckBox = ({ className, listData, reset, setSelectCoin, selectCoin }: any) => {
  const [state, setState] = useState(true);
  const [list, setList] = useState(Object.values(listData) ?? []);

  useEffect(() => {
    setList(Object.values(listData) ?? []);
  }, [listData]);


  useEffect(() => {
    if (reset?.trim()?.length > 0) {
      setState(false)
      setTimeout(() => {
        setState(true)
      }, 100);
    }
  }, [reset]);

  return (
    <div className={`listCoinPopup listCoinCheckBox ${className}`}>

      {state
        ? list?.map((i: any, n: any) =>
          <label htmlFor={i?.value} key={n} className="item">
            <div className="name cPointer">
              <input className="checkSelect" type="checkbox" id={i?.value}
                name="" defaultChecked={selectCoin[i?.value]?.isChecke ? selectCoin[i?.value]?.isChecke : false}
                onChange={(e: any) => setSelectCoin((prevState: any) => ({
                  ...prevState, [i?.value]: { isChecke: e.target.checked, detail: i ,myId:i?.value}
                }))} />
              <p className="p1">{i?.name}</p>
            </div>
          </label>)
        : <CircularProgress style={{ color: "#2424da", margin: "auto" }} />
      }
    </div>
  )
}

export default ListTextCheckBox