import * as BackdropShow from "../../services/redux/backdrop";
import VerificationInput from "react-verification-input";
import LogoHeader from '../../components/LogoHeader';
import { toEnglishDigits } from "../../helpers/tools";
import { config } from "../../config/config";
import { useTranslation } from "react-i18next";
import gather from "../../helpers/gather";
import { useDispatch } from "react-redux";
import Countdown from "react-countdown";
import { useState } from "react";
import Cookies from "js-cookie";
import _ from "lodash";
import useSnack from "../../hooks/useSnack";
import { useNavigate } from "react-router-dom";

const VerifyLogin = ({ setChangePage, mobile, body, type }: { setChangePage: any, mobile: string, body: any, type: string }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [showTimer, setShowTimer] = useState({ flag: 1, show: true, myTime: Date.now() + 240000 });
  const [code, setCode] = useState('');
  const snackUse = useSnack();
  const navigati = useNavigate();

  const send = async (code: any) => {
    dispatch(BackdropShow.showCircle());

    let newBody: any = {
      mobile: toEnglishDigits(mobile?.toString()),
      code: toEnglishDigits(code?.toString()),
      type: type
    }

    const result = await gather(`${config.api}/v1/auth/signin/verify`).post(newBody)

    if (result.code === 200) {
      Cookies.set('accessTokenPanelEx', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('refreshTokenPanelEx', result.data.refreshToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      await new Promise(res => setTimeout(res, 500));

      navigati("/")
      window.location.reload();

    } else {
      snackUse.showError(t(result.message) || t("99999"));
      dispatch(BackdropShow.hideCircle());
      setCode("");
    }
  }

  const reloadCode = async () => {
    if (showTimer.show) return;

    dispatch(BackdropShow.showCircle());

    const result = await gather(`${config.api}/v1/auth/signin/confirm`).post(body)

    if (result.message === 200) {
      snackUse.showSaccess("درخواست ارسال شد");
      setShowTimer({ flag: 1, show: true, myTime: Date.now() + 240000 });
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hideCircle());
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3 ></h3>;
    } else {
      return <span>0{minutes}:{seconds < 10 && "0"}{seconds}</span>;
    }
  };

  return (
    <div className="loginPage">
      <div className=" login">
        <LogoHeader />
        <div className="loginBody">
          {type == "gAuth"
            ? <div className="upTitle">
              <h5 className="rtl"> کد تایید Google Authenticator</h5>
              <p className="rtl">کد دو عاملی خود را وارد کنید</p>
            </div>
            : <div className="upTitle">
              <h5>تاییدیه پیامکی</h5>
              <p>کد تایید برای شماره {mobile} پیامک شد</p>
            </div>}
          <div className="textCenter">
            <p className="sign flex">
              <span className="icon-editenum " ></span>
              <span onClick={() => setChangePage("login")}>
                ویرایش شماره همراه
              </span>
            </p>
          </div>
          <div className="inputs">
            <div className="text-right">
              {type == "gAuth"
                ? <span className="textvr rtl">کد Google Authenticator</span>
                : <span className="textvr">کد تایید</span>}
            </div>
            <div className='codeNumberBox'>
              <VerificationInput
                onComplete={(e) => send(e)}
                onChange={(e) => { Number(e) >= 0 && setCode(e) }}
                value={code}
                placeholder='-'
                inputProps={{ type: "tel" }}
                classNames={{
                  container: "containerVerify",
                  character: "characterVerify",
                  characterFilled: "character--filled",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
            </div>
            {type == "gAuth"
              ? <div className="disableBtn ">
                <h5 className="again">ارسال مجدد</h5>
              </div>
              : <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
                {showTimer.show &&
                  <div className="btn">
                    <Countdown date={showTimer.myTime} renderer={renderer}
                      onComplete={() => setShowTimer({ flag: 0, show: false, myTime: Date.now() + 240000 })} />
                  </div>}
                {!showTimer.show &&
                  <h5 className="again">ارسال مجدد</h5>}
                <div className="progress"></div>
              </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyLogin