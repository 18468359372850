import React from 'react'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'

const NavChangeDeposit = () => {
  return (
    <div className='changeNavStyle'>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.default.url} className='item font-12'>واریز تومان</NavLink>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.high.url} className='item itemWithNew font-12'>
        واریز شناسه‌دار
        <span className='txtNew'>جدید</span>
      </NavLink>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.card.url} className='item itemWithNew font-12'>
        کارت به کارت
        <span className='txtNew font-12'>جدید</span>
      </NavLink>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCoin.url} className='item font-12'>واریز ارز دیجیتال</NavLink>
    </div>
  )
}

export default NavChangeDeposit