import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import _ from 'lodash'
import DocUserUpgrade from './items/DocUserUpgrade'
import RoleUpgrade from './items/RoleUpgrade'
import ShowAllLevel from './items/ShowAllLevel'
import { menu } from '../../config/menu'
import TopUpgrade from './items/TopUpgrade'
import DetailsUser from './items/DetailsUser'
import AddAccountBank from './items/AddAccountBank'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as BackdropShow from '../../services/redux/backdrop'
import gather from '../../helpers/gather'
import { config } from '../../config/config'
import { getProfile } from '../../services/redux/user'
import ConfirmUpgrade from './dialogs/ConfirmUpgrade'
import * as DialogShow from '../../services/redux/dialog'
import useSnack from '../../hooks/useSnack'
import Footer from '../../components/Footer'

const UpgradeLevel = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [changeview, setChangeview]: any = useState("showAll");
  const [flagChangeview, setFlagChangeview]: any = useState("showAll");
  const profile = useSelector((state: any) => state.user.profile);
  const [loadingPage, setLoadingPage] = useState(true)

  const handleChange = () => {
    setLoadingPage(true);
    if (changeview == "showAll") {
      setFlagChangeview("showAll");
    } else if (changeview == "role") {
      if (!profile?.verify?.rules) {
        setFlagChangeview("role");
      } else if (!profile?.verify?.document) {
        setFlagChangeview("nationalCard");
      } else {
        setFlagChangeview("showAll");
        sendUpgrade()
      }
    } else if (changeview == "nationalCard") {
      if (!profile?.verify?.document) {
        setFlagChangeview("nationalCard");
      } else {
        setFlagChangeview("showAll");
        sendUpgrade()
      }
    } else if (changeview == "DocUpgrade") {
      setFlagChangeview(changeview);

    } else if (changeview == "detailUSer") {
      if (profile?.verify?.identity) {
        setFlagChangeview("showAll");
        sendUpgrade()
      } else {
        setFlagChangeview("detailUSer");
      }
    } if (changeview == "addCard") {
      if (profile?.verify?.card) {
        setFlagChangeview("showAll");
        sendUpgrade()
      } else {
        setFlagChangeview("addCard");
      }
    }
    setLoadingPage(false);
  }

  const sendUpgrade = async () => {
    dispatch(BackdropShow.showCircle());

    const result = await gather(`${config.api}/v1/user/levelUp`).get();

    if (result.code === 200) {
      // dispatch(DialogShow.show(<ConfirmUpgrade text={"تبریک ! شما هم اکنون  می توانید از ویژگی های سطح جدید استفاده کنید"} close={() => dispatch(DialogShow.hide())} />))
      dispatch(DialogShow.show(<ConfirmUpgrade text={" درخواست شما با موفقیت ثبت شد"} close={() => dispatch(DialogShow.hide())} />))
      await dispatch(getProfile());
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hideCircle());
  }

  useEffect(() => {
    handleChange();
  }, [changeview])

  useEffect(() => {
    setLoadingPage(true);
    if ((profile?.verify && !profile?.verify?.identity) ||
      _.isNull(profile?.nationalCode)
      || _.isNull(profile?.birthDate) || profile?.nationalCode?.trim().length != 10
      || profile?.birthDate?.trim().length == 0
    ) {
      setFlagChangeview("detailUSer");
      setLoadingPage(false);
      return;
    }
    setLoadingPage(false);
  }, [profile])


  useEffect(() => {
    document.title = menu.upgradeLevel.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          {loadingPage
            ? <div className='upgradeLevel loadingPage'>
              <video muted autoPlay loop >
                <source src="/video/loadingEX.mp4" type="video/mp4" />
              </video>
            </div>
            : <div className='upgradeLevel'>
              {flagChangeview === "showAll"
                ? <ShowAllLevel setChangeview={setChangeview} />
                : flagChangeview === "role"
                  ? <RoleUpgrade send={setChangeview} />
                  : flagChangeview === "nationalCard"
                    ? <DocUserUpgrade send={setChangeview} />
                    : flagChangeview == "DocUpgrade"
                      ? <TopUpgrade setChangeview={setChangeview} />
                      : flagChangeview === "detailUSer"
                        ? <DetailsUser setChangeview={setChangeview} />
                        : flagChangeview === "addCard"
                          ? <AddAccountBank setChangeview={setChangeview} />
                          : ""
              }
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default UpgradeLevel