import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'
import { useLocalStorage } from 'usehooks-ts';
import { FormControlLabel, Switch } from '@mui/material';

const MenuProfile = ({ exit, level, btTheme }: any) => {
  const [theme, setTheme] = useLocalStorage('themeEX', "light");

  const changeTheme = (e: any) => {
    e.preventDefault()
    setTheme(theme == "light" ? "dark" : "light")
  }

  return (
    <div className="newMenuProfile">
      <div className='levelInfo'>
        <h5> کاربر سطح {level}</h5>
        <img src="/icons/m-IconFlashCircle.svg" alt="" />
      </div>
      <div className='dividerVer'></div>

      <NavLink className='item' to={menu.setting.childs.accountUser.url} >
        <span className='icon-IConUser'></span>
        <div>
          <h5>مشاهده پروفایل</h5>
          <p>نمایش اطلاعات حساب کاربری</p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.financial.childs.wallet.url} >
        <span className='icon-IConWallet2'></span>
        <div>
          <h5>کیف پول من</h5>
          <p>کیف پول خود را مدیریت کنید</p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.upgradeLevel.url} >
        <span className='icon-IConFingerScan'></span>
        <div>
          <h5>احراز هویت</h5>
          <p>بسادگی احراز هویت انجام دهید</p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.userBank.childs.account.url} >
        <span className='icon-IConCardTick'></span>
        <div>
          <h5>کارت های بانکی</h5>
          <p>مدیریت راحت کارت های بانکی </p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.alertCoin.url} >
        <span className='icon-IconBell'></span>
        <div>
          <h5>هشدار قیمت</h5>
          <p>زنگوله قیمت رو تنظیم کن </p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.giftCode.url} >
        <span className='icon-IconGift'></span>
        <div>
          <h5>کارت هدیه</h5>
          <p>کارت هدیه خودت اینجا وارد کن </p>
        </div>
      </NavLink>
      <button className='item deActiveBtn'
      //  to={menu.invitationCode.url}
        >
        <span className='icon-IconInvitation'></span>
        <div>
          <h5>کد دعوت</h5>
          <p>یک دعوت سودمند </p>
        </div>
      </button>
      {btTheme
        &&
        <div className='item itemTheme' onClick={changeTheme} >
          <span className={`${theme == "light" ? "icon-sun" : "icon-moon"}`}></span>
          <div>
            <FormControlLabel control={<Switch checked={theme == "light" ? false : true} />} label={theme == "light" ? "روشن" : "تاریک"} />
          </div>
        </div>
      }

      <div className='dividerVer'></div>
      <div className='item' onClick={exit}>
        <img src="/icons/m-IConLogout.svg" alt="" />
        <div>
          <h5>خروج</h5>
          <p>از حساب کاربری خود خارج شوید</p>
        </div>
      </div>
    </div>
  )
}

export default MenuProfile