import { Box, CircularProgress, Divider, FormControlLabel, Radio, RadioGroup, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { config } from '../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { zeroFormat, removeComma, toEnglishDigits, toFixed, priceZeroDecimal } from '../../../../helpers/tools';
import { marksSlidBar } from '../../../../config/variable';
import { useTranslation } from 'react-i18next';
import * as BackdropShow from '../../../../services/redux/backdrop';
import gather from '../../../../helpers/gather';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useTicker from '../../../../hooks/useTicker';
import { menu } from '../../../../config/menu';
import { NavLink } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import * as DialogShow from '../../../../services/redux/dialog';
import NeedLogin from '../../../../components/NeedLogin';
import useSnack from '../../../../hooks/useSnack';
import ConfirmBuySellFast from '../../dialog/ConfirmBuySellFast';
import { useLocalStorage } from 'usehooks-ts';
import CustomSelectBox2 from '../../../../components/CustomSelectBox2';
import WarningMemo from '../../../depositAll/dialogs/WarningMemo';
import CustomInputText from '../../../../components/CustomInputText';
import NeedUpgrade from '../../../../components/NeedUpgrade';
import ListWithdraw from '../../../withdrawAll/dialogs/ListWithdraw';
import VerifyWithdrawCoin from '../../../withdrawAll/dialogs/VerifyWithdrawCoin';

const FastOrder = ({ orderChange, setOrderChange, coin, setReload }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const listCoinObjByID = useSelector((state: any) => state.publicApis?.listCoinObjByID);
  const mainWalletObj: any = useSelector((state: any) => state.user?.mainWalletObj);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const profile: any = useSelector((state: any) => state.user?.profile);
  const [payment, setPayment] = useState(0);
  const [amount, setAmount] = useState(0);
  const [valueSlider, setValueSlider] = useState(100);
  const ticker = useTicker();
  const snackUse = useSnack();
  const [confiemOrder] = useLocalStorage('confiemOrder', "false");
  const [maxOrder, setMaxOrder] = useState(false);
  const [myLoading, setMyLoading] = useState(false);
  const [radioBuy, setRadioBuy] = React.useState('defualt');
  const [networkSelectError, setNetworkSelectError] = useState(false);
  const [networkSelect, setNetworkSelect]: any = useState({});
  const [needTagMemo, setNeedTagMemo] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [minWitdraw, setMinWitdraw] = useState(false);
  const [tagMemo, setTagMemo] = useState('');


  const resetWithdraw = () => {
    setRadioBuy("defualt")
    setNetworkSelectError(false);
    setNetworkSelect({});
    setNeedTagMemo(false);
    setAddress("");
    setAddressError(false);
    setTagMemo("");
  }

  const justEnglish = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setAddress(e);
    } else {
      snackUse.showError("فقط حروف لاتین برای آدرس کیف پول وارد کنید");
    }
  };

  const justNum = (e: any) => {
    const re = /^[^\u0600-\u06FF]+$/;
    if (e === "" || re.test(e)) {
      setTagMemo(e);
    } else {
      snackUse.showError("فقط اعداد و حروف لاتین برای آدرس ممو وارد کنید");
    }
  };

  const handelChange = (e: any) => {
    // if (amount > mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free) {
    //   setAmount(removeComma(e));
    //   return
    // }

    setAmount(removeComma(e));
    const price = ticker[orderChange ? 'buy' : 'sell'](coin.symbol + 'tmn');
    const total = orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setPayment(toFixed(total));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(e)) / balance) * 100);
    setValueSlider(pers <= 0 ? 0 : pers);
  }

  const handelChangeTMN = (e: any) => {

    setPayment(removeComma(e));
    const price = ticker[orderChange ? 'buy' : 'sell'](coin.symbol + 'tmn');
    const total = !orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setAmount(toFixed(total));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(total)) / balance) * 100);
    setValueSlider(pers <= 0 ? 0 : pers);
  }

  const pastWallet = async () => {
    const text = await navigator.clipboard.readText();
    justEnglish(text)
  }

  const hanleSelectNetwork = (e: any) => {
    setNeedTagMemo(false);
    setAddress("");
    setAddressError(false);
    setTagMemo("");

    setNetworkSelect(e);

    if (e?.network?.memo?.isForce || e?.network?.memo?.isNeed) {
      setNeedTagMemo(true);
      dispatch(DialogShow.show(<WarningMemo text="برداشت" coin={e?.network?.name?.toUpperCase()} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const handleMaxWithdraw = () => {
    const daily = Number(profile?.limit?.coin?.daily);
    const monthly = Number(profile?.limit?.coin?.monthly);
    const maxMonthly = Number(profile?.plane?.level?.withdraw?.coin?.monthly);
    const maxDaily = Number(profile?.plane?.level?.withdraw?.coin?.daily);

    const calcDaily = Number(maxDaily - daily) ?? 0;
    const calcMonthly = Number(maxMonthly - monthly) ?? 0;

    return { calcDaily, calcMonthly }
  }

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioBuy((event.target as HTMLInputElement).value);
  };

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);
    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;

    const percent = 100 - Number(newValue);

    const amount = Number(balance) * Number(percent / 100) || 0;
    handelChange(toFixed(amount, orderChange ? 0 : coin?.decim))
  }

  const confirmSend = () => {

    const myAmount = orderChange ? Number(toEnglishDigits(payment?.toString()))
      : Number(toEnglishDigits(amount?.toString()));

    const myBuySell = orderChange ? Number(toEnglishDigits(amount?.toString()))
      : Number(toEnglishDigits(payment?.toString()));

    if (_.isEmpty(coin)) {
      snackUse.showError('لطفا یک ارز را انتخاب کنید');
      return
    }

    if (myAmount <= 0) {
      snackUse.showError('مقدار را وارد کنید');
      return
    }

    if (radioBuy !== "defualt") {
      if (coin?.symbol?.toLocaleLowerCase() != "usdt" && !ticker.price(coin?.symbol + 'usdt')) {
        snackUse.showError("دردسترس نیست");
        return
      }

      if (profile?.plane?.level?.index === 0 || _.isNull(profile?.plane?.level?.index)) {
        dispatch(DialogShow.show2(<NeedUpgrade />))
        return
      }

      if (_.isEmpty(networkSelect)) {
        snackUse.showError("لطفا شبکه انتقال را انتخاب کنید");
        setNetworkSelectError(true)
        setTimeout(() => { setNetworkSelectError(false) }, 3000)
        return;
      }

      if (!networkSelect?.withdraw?.isActive) {
        snackUse.showError("این ارز در این شبکه قابلیت برداشت ندارد");
        return
      }

      if (address.length < 20) {
        snackUse.showError("آدرس مقصد را بررسی کنید");
        setAddressError(true)
        setTimeout(() => { setAddressError(false) }, 3000)
        return;
      }
      if (networkSelect?.network?.regex &&
        !new RegExp(networkSelect?.network?.regex).test(address)) {
        snackUse.showError("آدرس مقصد را معتبر نیست");
        setAddressError(true)
        setTimeout(() => { setAddressError(false) }, 3000)
        return;
      }


      if ((myAmount * ticker.price(coin?.symbol + 'usdt', 1)) > handleMaxWithdraw().calcDaily || (myAmount * ticker.price(coin?.symbol + 'usdt', 1)) > handleMaxWithdraw().calcMonthly) {
        snackUse.showError(`مقدار برداشت درخواست شده بیش از سقف مجاز است`);
        return;
      }

      if (myAmount < networkSelect?.withdraw?.min) {
        snackUse.showError("حداقل مقدار برداشت را رعایت کنید");
        setMinWitdraw(true)
        setTimeout(() => { setMinWitdraw(false) }, 3000)
        return;
      }

      if (networkSelect?.network?.memo?.isForce && tagMemo?.trim().length <= 0) {
        snackUse.showError(" تگ / ممو را وارد کنید");
        return;
      }
    }


    if (confiemOrder == "true") {
      send({
        'coin': coin._id,
        'pair': 'tmn',
        'side': orderChange ? "buy" : "sell",
        'price': ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn'),
        "amount": orderChange
          ? toFixed(myBuySell / ticker['buy'](coin?.symbol + 'tmn'), coin?.decimal)
          : toFixed(amount, coin?.decimal)
        ,
        "trade": "otc",
        "stop": null
      })
    } else {
      const data = {
        "coin": coin,
        "side": orderChange ? "buy" : "sell",
        'amount': myAmount,
        "radioBuy": radioBuy,
        "network": networkSelect,
        "needTagMemo": needTagMemo,
        "address": address,
        "tagMemo": tagMemo,
        "myBuySell": myBuySell
      }
      dispatch(DialogShow.show(<ConfirmBuySellFast data={data} send={send} close={() => dispatch(DialogShow.hide())} />))
    }
  }

  const withdrawCoin = async () => {
    const myAmount = orderChange ? toFixed(toEnglishDigits(payment?.toString()), coin?.decimal)
      : toFixed(toEnglishDigits(amount?.toString()), coin?.decimal);

    setMyLoading(true);

    const body: any = {
      coin: coin?._id,
      network: networkSelect?.network?._id,
      receiver: toEnglishDigits(address),
      amount: Number(toEnglishDigits(myAmount?.toString())),
    }

    if (networkSelect?.network?.memo?.isForce || networkSelect?.network?.memo?.isNeed) {
      body["memo"] = tagMemo
    }

    const result = await gather(`${config.api}/v1/withdraw`).post(body);
    if (result.message === 200) {
      dispatch(DialogShow.show(<VerifyWithdrawCoin body={body} mobile={profile?.mobile ?? "---"} submit={submit} type={result?.data?.type} close={() => dispatch(DialogShow.hide())} />))
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  ///////////////////

  const submit = async (body: any) => {
    dispatch(BackdropShow.showCircle());

    const result = await gather(`${config.api}/v1/withdraw/confirm`).post(body);
    if (result.message === 200) {
      setReload(new Date().toString())
      snackUse.showSaccess("عملیات با موفقیت انجام شد");
      dispatch(DialogShow.hide())
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hideCircle());
  }

  /////////////////////

  const handleSelectAddressBook = (item: any) => {

    if (coin?._id == item?.coin?._id) {
      // setAddress(item?.address);
      justEnglish(item?.address)
      setNetworkSelect(item?.network)

      const find = listCoinObjByID[item?.coin?._id]?.networks?.filter((e: any) =>
        e?.network?._id?.toLowerCase() == item?.network?._id);

      if (find) {
        setNetworkSelect(find[0]);
        if (item?.memo?.trim().length > 0) {
          setNeedTagMemo(true);
          justNum(item?.memo);
        }
      }

    } else {
      setAddress('');
      setTagMemo('');
      setNetworkSelect({})
      snackUse.showError('آدرس وارد شده با ارز انتخابی  یکسان نیست');
    }
  }

  const send = async (body: any) => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/order`).post(body);
    dispatch(DialogShow.hide())

    if (result.message === 200) {
      setReload(new Date().getTime().toString());
      snackUse.showSaccess('سفارش شما با موفقیت ثبت شد');
      setValueSlider(100);
      setAmount(0);
      setPayment(0);
      if (radioBuy !== "defualt") {
        withdrawCoin()
      }
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }

  useEffect(() => {
    setAmount(0);
    setPayment(0);
    setValueSlider(100);
    resetWithdraw();
  }, [coin])

  const handleTitleBar = () => {
    let title = `${zeroFormat(ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'tmn'))}`
      + " " + "تومان"
      + " | " + (orderChange ? "خرید" : "فروش")
      + " " + (coin?.nameFa ?? " -- ")
      + " " + "فوری"
      + " " + menu.order.childs.fastOrder.title;

    document.title = title
  }

  useEffect(() => {
    handleTitleBar()
  }, [orderChange, coin, ticker]);

  useEffect(() => {
    const price = ticker[orderChange ? 'buy' : 'sell'](coin.symbol + 'tmn');
    const total = orderChange
      ? Number(removeComma(amount)) / Number(price ?? 0)
      : Number(removeComma(amount)) * Number(price ?? 0)

    setPayment(toFixed(total));
  }, [ticker]);


  return (
    <>
      <div className='stepOrder'>
        <div>
          <span className='icon-SecurityCard'></span>
          <h4>مرحله دوم <b>نوع معامله</b></h4>
          <div className='dividerVer'></div>
        </div>
        <p>نوع معامله را مشخص کنید</p>
      </div>
      <div className='bodyFastOrder'>
        <div className='right'>
          <div className='btnChangeOrder'>
            <button className={`buy ${orderChange && 'active'}`} onClick={() => { setOrderChange(true); setPayment(0); setAmount(0); setValueSlider(100); }}>
              <span className='m-IconBuy'></span>
              <p>خرید</p>
            </button>
            <button className={`sell ${!orderChange && 'active'}`} onClick={() => { setOrderChange(false); setPayment(0); setAmount(0); setValueSlider(100); }}>
              <span className='m-IconSell'></span>
              <p>فروش</p>
            </button>
          </div>
          <div className='enterAmount'>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={!orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} decimalScale={orderChange ? 0 : coin?.decimal}
                value={amount > 0 ? amount : ''} onChange={(e) => { handelChange(e.target.value) }} placeholder='پرداخت میکنم' />
            </div>
            <div className='btnExchange' onClick={() => { setOrderChange(!orderChange); setPayment(amount); setAmount(payment); setValueSlider(100); }}>
              <LazyLoadImage crossOrigin='anonymous' src='/icons/m-IconRepeat.svg' alt='' />
            </div>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} decimalScale={!orderChange ? 0 : coin?.decimal}
                value={payment > 0 ? payment : ''} onChange={(e) => handelChangeTMN(e.target.value)} placeholder='دریافت میکنم' />
            </div>
          </div>
          <p className={`wrningText mb-10 ${!maxOrder && "opacity0"}`}>مقدار وارد شده، از حداکثر مقدار مجاز بیشتر می‌باشد.</p>
          <div className='priceNow'>
            {/* <h4>قیمت تومانی در لحظه:  <b>TMN {zeroFormat(ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'tmn'))}</b></h4> */}
            {/* <h4>قیمت دلاری در لحظه:  <b>{zeroFormat(coin?.symbol == 'usdt' ? 1 : ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'usdt'), coin?.pair?.usdt?.precision, false)}$</b></h4> */}
            <div className='dollarPriceShow'>
              <h4>قیمت تومانی در لحظه:</h4>
              <h4 className='ltr'>TMN {priceZeroDecimal(ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'tmn'))}</h4>
            </div>
            <div className='dollarPriceShow'>
              <h4>قیمت دلاری در لحظه:</h4>
              <h4 className='ltr'>{priceZeroDecimal(coin?.symbol == 'usdt' ? 1 : ticker[orderChange ? 'buy' : 'sell'](coin?.symbol + 'usdt'), coin?.pair?.usdt?.precision, false)} $</h4>
            </div>
          </div>
          <div className='sliderRage my-20'>
            <Box sx={{ width: '100%' }}>
              <Slider
                aria-label='Restricted values'
                track='inverted'
                value={typeof valueSlider === 'number' ? valueSlider < 0 ? 100 : valueSlider : 100}
                onChange={handleSliderBar}
                marks={marksSlidBar}
              // step={25}
              // sx={{
              //   color: theme.palette.mode === 'dark' ? '#fff' : '#0f0f0f33',
              //   '& .MuiSlider-track': {
              //     border: `3px solid ${'#2424da'}`,
              //     boxShadow: '0px 7.45px 14.91px 1.45px #2424da',
              //   },
              //   '& .MuiSlider-thumb': {
              //     width: 19,
              //     height: 19,
              //     backgroundColor: '#2424da',
              //     border: `4px solid ${'#2424da'}`,
              //     // position: 'relative',
              //     '&::before': {
              //       boxShadow: '1px 12px 20px 3px #2424da',
              //     },
              //     '&:hover, &.Mui-focusVisible, &.Mui-active': {
              //       boxShadow: 'none',
              //     },
              //     '&::after': {
              //       position: 'absolute',
              //       content: '""',
              //       width: '2px',
              //       height: '15px',
              //       background: '#2424da',
              //       top: '23px',
              //       right: '5px',
              //       borderRadius: '0px'
              //     },
              //   },
              //   '& .MuiSlider-mark': {
              //     backgroundColor: '#e0e0e0',
              //     width: 8,
              //     height: 8,
              //     borderRadius: 50,
              //   },
              //   '& .MuiSlider-markActive': {
              //     backgroundColor: `${'#2424da'}`,
              //   }
              // }}
              />
            </Box>
          </div>
          {(userIsLogin && orderChange) &&
            <div className='selectWalletBuy'>
              <p className='t1'>روش دریافت:</p>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioBuy}
                onChange={handleChangeRadio}
              >
                <FormControlLabel value="defualt" control={<Radio />} label="کیف پول داخلی اکسنوین" />
                <FormControlLabel value="other" control={<Radio />} label="انتقال به کیف پول دیگر" />
              </RadioGroup>
              {radioBuy !== "defualt"
                &&
                <>
                  <div className='adressBox'>
                    <CustomSelectBox2 flag={false} element={
                      <div className='showCoin'>
                        <h4 >{networkSelect?.network?.name ?? <b>انتخاب شبکه</b>}</h4>
                      </div>} typeFee={"withdraw"}
                      error={networkSelectError} symbol={coin?.symbol?.toUpperCase() ?? ''} listData={coin?.networks ?? []}
                      setInputSatet={hanleSelectNetwork} className={"maxWidth"} />

                    <CustomInputText label={''} inputState={address} setInputSatet={justEnglish} className={"py-12"}
                      error={addressError} classEnd={"brNone"} placeholder={"آدرس مقصد را وارد کنید"}
                      endEle={<span onClick={pastWallet} className='icon-IconPaste2 cPointer'></span>} />
                    <div className="addCard gap-2 " onClick={() =>
                      dispatch(DialogShow.show(<ListWithdraw setChooseItem={handleSelectAddressBook} close={() => dispatch(DialogShow.hide())} />))
                    }>
                      <span className='icon_addressBook'></span>
                    </div>
                  </div>
                  {needTagMemo
                    &&
                    <CustomInputText label={''} inputState={tagMemo} setInputSatet={justNum} className={"py-12"}
                      classEnd={"brNone"} placeholder={"تگ / memo"} />
                  }
                  {minWitdraw
                    &&
                    <p className={`wrningText`}>حداقل مقدار برداشت {coin?.symbol} {networkSelect?.withdraw?.min} می باشد</p>
                  }
                  <div className='feeReceiveBuy'>
                    <p>کارمزد</p>
                    <p><b>{coin?.symbol?.toUpperCase()} </b> {zeroFormat(networkSelect?.withdraw?.fee ?? 0)} </p>
                  </div>
                  {/* <div className='feeReceiveBuy'>
                    <p>حداقل مقدار برداشت</p>
                    <p><b>{coin?.symbol?.toUpperCase()} </b> {zeroFormat(networkSelect?.withdraw?.min??0)} </p>
                  </div> */}
                  <div className='feeReceiveBuy'>
                    <p>مقدار دریافتی</p>
                    <p>{Number(payment) - Number(networkSelect?.withdraw?.fee) > 0 ? zeroFormat(Number(payment) - Number(networkSelect?.withdraw?.fee)) : 0} <b>{coin?.symbol?.toUpperCase()}</b></p>
                  </div>
                </>
              }
            </div>
          }

          {userIsLogin
            ? myLoading
              ? <div className={`loadingCircularOrder ${orderChange ? 'buy' : 'sell'}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <div className={`btnConfirm ${orderChange ? 'buy' : 'sell'}`} onClick={confirmSend}>
                <p> (با تایید {orderChange ? 'خرید' : 'فروش'} قوانین و مقررات را می پذیرم)</p>
                <Divider variant='middle' orientation='vertical' flexItem />
                <button>تایید {orderChange ? 'خرید' : 'فروش'}</button>
              </div>
            : <div className={`btnConfirm justifyCenter ${orderChange ? 'buy' : 'sell'}`}
              onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}>
              <p className='textCenter'>ثبت نام - ورود</p>
            </div>
          }
          {/* <p className='warningText'>کاربر گرامی به دلیل نوسانات قیمت  مقدار دریافتی شما به صورت تقریبی محاسبه گردیده است</p> */}
        </div>
        {userIsLogin
          && <div className='left'>
            <NavLink
              to={orderChange ? menu.financial.childs.depositAll.childs.depositCash.childs.default.url : menu.financial.childs.depositAll.childs.depositCoin.url + "/" + coin?.symbol?.toUpperCase()}
              className='cPointer flexCenter ml-5'>
              <IoIosAddCircleOutline color='var(--color-textDone)' />
            </NavLink>
            <h4>موجودی شما : </h4>
            <h4 className='cPointer mr-3' onClick={() => handelChange(mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free)}
            > {zeroFormat(mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free)}</h4>
            <h4 className='mr-2'>{orderChange ? 'TMN' : coin?.symbol?.toUpperCase()}</h4>
          </div>
        }
      </div>
    </>
  )
}

export default FastOrder