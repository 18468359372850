import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { menu } from '../../config/menu'
import _ from 'lodash'
import NavMenu from './NavMenu'
import { listMenu, socialLink } from '../../config/variable'
import { useLocalStorage } from 'usehooks-ts'
import { formatter, zeroFormat } from '../../helpers/tools'
import { useDispatch, useSelector } from 'react-redux'
import useBalanceFix from '../../hooks/useBalanceFix'
import * as DialogShow from '../../services/redux/dialog'
import NeedLogin from '../NeedLogin'

const Navbar = ({ close = () => { }, flag = false }: any) => {
  const dispatch = useDispatch<any>();
  const [theme] = useLocalStorage('themeEX', "light");
  const location = useLocation();
  const [open, setOpen] = useState(!flag)
  const refParent: any = useRef(null);
  const refChild: any = useRef(null);
  const refImgLogo: any = useRef(null);
  const balance = useBalanceFix();
  const profile: any = useSelector((state: any) => state.publicApis?.profile);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const listVoucherSymbol = useSelector((state: any) => state.publicApis?.listVoucherSymbol);
  const urlList = [menu.learning.url, menu.order.childs.currency.url, menu.order.childs.fastOrder.url, menu.order.childs.orederPro.url]

  const handle = () => {
    const heightChild = refChild?.current?.getBoundingClientRect().width;
    if (open) {
      refParent.current.style.width = `${heightChild}px`;
      if (flag) {
        refImgLogo.current.style.width = '129px';
        refImgLogo.current.style.height = '124px';
      } else {
        refImgLogo.current.style.width = '180px';
        refImgLogo.current.style.height = '151px';
      }
    } else {
      refParent.current.style.width = "35px";
      if (flag) {
        refImgLogo.current.style.width = '88px';
        refImgLogo.current.style.height = '84px';
      } else {
        refImgLogo.current.style.width = '180px';
        refImgLogo.current.style.height = '151px';
      }
    }
  }

  useEffect(() => {
    handle()
  }, [open])

  const activeLine = (url: string) => {
    return _.startsWith(location.pathname, url) ? "active" : ""
  }

  return (
    <nav className={`newNavbar ${flag ? "disPlayNavbar" : ""}`}
      onMouseEnter={() => flag && setOpen(true)}
      onMouseLeave={() => flag && setOpen(false)}
    >
      <div ref={refParent} className='boxNav'>
        <div ref={refChild} className='body'>
          <img className='logoTop' ref={refImgLogo} src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" />
          <img className='btBackClose' src="/icons/m-ArrowCircleRight.svg" alt="" onClick={() => setOpen(!open)} />
          <div className="navControl">
            {userIsLogin
              && <>
                <div className={`showDetailInNav ${!open && "opacity0"}`}>
                  <p>جمع کل دارایی:</p>
                  <p className='fontYekanBakh textleft'>{formatter(Object.values(balance)?.filter((e: any) => !Object?.values(listVoucherSymbol)?.includes(e.symbol))?.reduce((n, e: any) => n + e.value.tmn, 0), 0, 0,true)} تومان</p>
                </div>
                <div className={`showDetailInNav level ${!open && "opacity0"}`}>
                  <p>سطح کنونی شما:</p>
                  <p>{profile?.plane?.level?.name ?? "--"}</p>
                </div>
              </>
            }
            <NavMenu profile={profile} userIsLogin={userIsLogin} dispatch={dispatch} urlList={urlList} reSize={open} setOpen={setOpen} title='خرید و فروش' icon='m-IconOrder' close={close} listNav={listMenu.buyAndSell} active={activeLine(menu.order.url)} />
            <NavMenu profile={profile} userIsLogin={userIsLogin} dispatch={dispatch} urlList={urlList} reSize={open} setOpen={setOpen} title='کیف پول' icon='iconWallet' close={close} listNav={listMenu.financial} active={activeLine(menu.financial.url)} />
            <NavMenu profile={profile} userIsLogin={userIsLogin} dispatch={dispatch} urlList={urlList} reSize={open} setOpen={setOpen} title='تیکت' icon='m-IconTicket' close={close} listNav={listMenu.ticket} active={activeLine(menu.ticket.url)} />
            <NavMenu profile={profile} userIsLogin={userIsLogin} dispatch={dispatch} urlList={urlList} reSize={open} setOpen={setOpen} title='حساب های بانکی' icon='m-IconCardBank' close={close} listNav={listMenu.userBank} active={activeLine(menu.userBank.url)} />
            <NavMenu profile={profile} userIsLogin={userIsLogin} dispatch={dispatch} urlList={urlList} reSize={open} setOpen={setOpen} title='تنظیمات' icon='m-IconSetting' close={close} listNav={listMenu.setting} active={activeLine(menu.setting.url)} />
            <div className='dividerVer'></div>
            <NavLink to={menu.learning.url} className={`item ${activeLine(menu.learning.url)}`} onClick={close}>
              <div>
                <span className='m-IconBook'></span>
                <p>آموزش</p>
              </div>
            </NavLink>
            {userIsLogin
              ? <NavLink to={menu.upgradeLevel.url} onClick={close} className={`item linkItem ${activeLine(menu.upgradeLevel.url)} ${open ? "upgradeAuth" : ""}`} >
                <div>
                  <span className='m-Crown'></span>
                  <p>ارتقا سطح حساب</p>
                </div>
              </NavLink>
              : <div onClick={() => {
                close();
                dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))
              }} className={`item linkItem ${open ? "upgradeAuth" : ""}`} >
                <div>
                  <span className='m-Crown'></span>
                  <p>ارتقا سطح حساب</p>
                </div>
              </div>
            }
            <div className={`menuSocial ${!open && "none"}`} >
              <p>شبکه های اجتماعی ما را دنبال کنید</p>
              <div className='iconSocial'>
                <Link target="_blank" to={socialLink.facebook}><img crossOrigin='anonymous' src="/icons/m-SocialFacebook.svg" alt="" /></Link>
                <Link target="_blank" to={socialLink.instagram}><img crossOrigin='anonymous' src="/icons/m-SocialInstagram.svg" alt="" /></Link>
                <Link target="_blank" to={socialLink.telegram}><img crossOrigin='anonymous' src="/icons/m-SocialTelegram.svg" alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar