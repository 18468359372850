import React from 'react';
import useTicker from '../../../hooks/useTicker';
import { formatter, zeroFormat } from '../../../helpers/tools';
import { useSelector } from 'react-redux';

const ItemTotalTmn = ({ data }: { data: any }) => {
    const selector = useSelector((state: any) => state.ticker);

    const handleTotal = () => {
        let sum = 0
        data?.forEach((i: any) => {
            if (i?.fiat) {
                sum = sum + i?.amount
            } else {
                sum = sum + Number(i?.amount * selector?.tickersApi[(i?.coin?.symbol?.toLocaleLowerCase() + "tmn")]?.close || 0)
            }
        });

        return formatter(sum, 0, 0, true)
    }

    return (
        <p className='p1 '>{handleTotal()} تومان</p>
    );
}

export default ItemTotalTmn;
