import { LazyLoadImage } from "react-lazy-load-image-component"
import { config } from "../config/config"
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material";
import _ from "lodash";

const ListCoinCheckBox = ({ className, listData, defalutText, reset, setSelectCoin, selectCoin }: any) => {
  const [state, setState] = useState(true);
  const [list, setList] = useState(Object.values(listData) ?? []);
  const [input, setInput]: any = useState(defalutText)

  const searchItem = (text: any) => {
    setInput(text)
    if (_.isEmpty(Object.values(listData))) return
    const find = Array.from(Object.values(listData)).filter((e: any) =>
      e?.name?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.symbol?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.nameFa?.toLowerCase()?.includes(text?.toLowerCase()));

    if (find) {
      setList([]);
      setTimeout(() => {
        setList(find);
      }, 100);
    } else {
      setList(Object.values(listData));
    }
  }

  useEffect(() => {
    setList(Object.values(listData) ?? []);
  }, [listData]);


  useEffect(() => {
    if (reset?.trim()?.length > 0) {
      setState(false)
      setTimeout(() => {
        setState(true)
      }, 100);
    }
  }, [reset]);


  return (
    <div className={`listCoinPopup listCoinCheckBox ${className}`}>
      <div className="searchBox">
        <input type="text" value={input} onChange={(e) => searchItem(e.target.value)} placeholder='جستچو . . ' />
      </div>
      {state
        ? list?.filter((e: any) => e?.isActive == true)?.map((i: any, n: any) =>
          <label htmlFor={i?._id} key={n} className="item"
          // onClick={() => onClick(i)}
          >
            <div className="name cPointer">
              <input className="checkSelect" type="checkbox" id={i?._id}
                name="" defaultChecked={selectCoin[i?._id]?.isChecke ? selectCoin[i?._id]?.isChecke : false}
                onChange={(e: any) => setSelectCoin((prevState: any) => ({
                  ...prevState, [i?._id]: { isChecke: e.target.checked, coin: i ,myId:i?._id}
                }))} />
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${i?.symbol?.toUpperCase()}.png`} alt="" />
              <p className="p1">{i?.nameFa}</p>
              <p className="p2">{i?.symbol?.toUpperCase()}</p>
            </div>
          </label>)
        : <CircularProgress style={{ color: "#2424da", margin: "auto" }} />
      }
    </div>
  )
}

export default ListCoinCheckBox