import LogoHeader from '../../components/LogoHeader';
import { IoArrowForwardOutline } from 'react-icons/io5';
import StepByStep from './StepByStep';
import MoblieStep from './MoblieStep';
import { CircularProgress, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import CustomSelectBox from '../../components/CustomSelectBox';
import moment from 'jalali-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { toEnglishDigits } from '../../helpers/tools';
import useSnack from '../../hooks/useSnack';

const AddDetails = ({ setChangePage }: any) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [family, setFamily] = useState('');
  const [address, setAddress] = useState('');
  const [nationalCode, setNationalCode] = useState('');
  const [birthDate, setBirthDate]: any = useState({ y: '', m: '', d: '' });
  const [errorName, setErrorName] = useState(false);
  const [errorFamily, setErrorFamily] = useState(false);
  const [errorNation, setErrorNation] = useState(false);
  const [errorBirt, setErrorBirt] = useState(false);
  const [errorAdress, setErrorAdress] = useState(false);
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const send = async () => {
    if (name.length < 3) {
      setErrorName(true);
      setTimeout(() => { setErrorName(false) }, 3000);
      return
    }

    if (family.length < 3) {
      setErrorFamily(true);
      setTimeout(() => { setErrorFamily(false) }, 3000);
      return
    }

    if (nationalCode.length !== 10) {
      setErrorNation(true);
      setTimeout(() => { setErrorNation(false) }, 3000);
      return
    }

    if (_.isEmpty(birthDate.y) || _.isEmpty(birthDate.d) || _.isEmpty(birthDate.m)) {
      setErrorBirt(true);
      setTimeout(() => { setErrorBirt(false) }, 3000);
      return
    }

    setMyLoading(true);
    const body = {
      name: name, family: family, address: address,
      nationalCode: toEnglishDigits(nationalCode?.toString()),
      birthDate: moment.from(birthDate.y + "-" + birthDate.m + "-" + birthDate.d, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD')
    }

    const result = await gather(`${config.api}/v1/user/verify`).post(body);

    if (result.code === 200) {
      setChangePage("userAddCard")
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const just_persian = (str: any) => {
    var p = /^[\u0600-\u06FF\s0-9]+$/;
    if (str?.trim().length == 0) {
      return true
    }

    if (!p.test(str)) {
      snackUse.showError("لطفا از حروف فارسی استفاده کنید");
      return false
    }
    return true
  }

  return (
    <div className='bodyMain'>
      <div className='newContainer'>
        <div className="authPage">
          <div className="container">
            <div className="auth">
              <LogoHeader />
              <div className='authBody'>
                <div className="top">
                  <div className="titleBack">
                    <div onClick={() => setChangePage("signup")}><IoArrowForwardOutline size={20} /></div>
                    <h5> احراز هویت</h5>
                  </div>
                  <MoblieStep classNames={"none"} step={"1"} />
                </div>
                <StepByStep current={1} />
                <div className="rowCenter">
                  <div className="inputsItem">
                    <div className="inputs">
                      <FormControl className={`formControlLogin ${errorName ? "redBorder" : ""}`} variant="outlined">
                        <InputLabel>نام*</InputLabel>
                        <OutlinedInput fullWidth={true} label="نام" type="text" value={name} onChange={(e) => just_persian(e.target.value) && setName(e.target.value)}
                          endAdornment={<span className='icon-userprofile '></span>} />
                      </FormControl>
                      <div className={`errorText ${errorName ? "opacity1" : ""}`}>
                        <span>ورودی خود را بازبینی کنید</span>
                      </div>
                      <FormControl className={`formControlLogin ${errorFamily ? "redBorder" : ""}`} variant="outlined">
                        <InputLabel>نام خانوادگی *</InputLabel>
                        <OutlinedInput fullWidth={true} label="نام خانوادگی" value={family} onChange={(e) => just_persian(e.target.value) && setFamily(e.target.value)}
                          type="text" endAdornment={<span className='icon-userprofile '></span>} />
                      </FormControl>
                      <div className={`errorText ${errorFamily ? "opacity1" : ""}`}>
                        <span>ورودی خود را بازبینی کنید</span>
                      </div>
                      <FormControl className={`formControlLogin ${errorNation ? "redBorder" : ""}`} variant="outlined">
                        <InputLabel>کد ملی*</InputLabel>
                        <OutlinedInput fullWidth={true} label="کد ملی" onChange={(e) => setNationalCode(e.target.value)}
                          type="text" endAdornment={<img crossOrigin='anonymous' className="iconInput" src="/icons/kartmelli.svg" alt="" />} />
                      </FormControl>
                      <div className={`errorText ${errorNation ? "opacity1" : ""}`}>
                        <span>ورودی خود را بازبینی کنید</span>
                      </div>
                    </div>
                    <div className="birthDate">
                      <span>تاریخ تولد*</span>
                      <div>
                        <CustomSelectBox error={errorBirt} text='روز' rang={31} sum={1} selecting={(e: any) => setBirthDate((birt: any) => ({ ...birt, d: e }))} />
                        <CustomSelectBox error={errorBirt} text='ماه' rang={12} sum={1} selecting={(e: any) => setBirthDate((birt: any) => ({ ...birt, m: e }))} />
                        <CustomSelectBox error={errorBirt} text='سال' rang={65} sum={1330} selecting={(e: any) => setBirthDate((birt: any) => ({ ...birt, y: e }))} />
                      </div>
                    </div>
                    <div className='textareaStyle'>
                      <div className="textRight">
                        <span className="oninput"> آدرس منزل*</span>
                      </div>
                      <div className={`inputGroup textarea ${errorAdress ? "brRed" : ""}`}>
                        <textarea value={address} onChange={(e) => just_persian(e.target.value) && setAddress(e.target.value)}
                          placeholder="آدرس کامل خود را وارد کنید "
                          rows={3}></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="textsItem">
                    <div className="textWarning">
                      <img crossOrigin='anonymous' src="/icons/Warning.1.svg" alt="" />
                      <h6>اطلاعیه</h6>
                    </div>
                    <p className='text'>
                      کاربر گرامی ، در صورتی که شخصی از شـما با عنوان استـخدام و سـرمایه گذاری و ... درخـواسـت عضـویـت/ثبـت نـام در مـجموعه خـدمات پرداخت نوین " ایـکس نوین " و یا درخواست مشخصات حسـاب کاربری و حـساب بـانـکی کــرد ، جــداً از انــجام این عــمل .خودداری کنید
                      در غیر اینصورت تمامی عواقب ناشی جرم پولشویی و معاونت .در جرم و جبران خسارت و ... به عهده شما میباشد
                    </p>
                    <div className='videoPlay'>
                      <ReactPlayer controls url='https://exnovin.net/direct/files/2020/10/ejare.mp4' />
                    </div>
                  </div>
                </div>
                <div className='btn'>
                  {
                    myLoading
                      ? <div className="loadingCircular "><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                      : <button className="finalCheckBtn upbtn" onClick={send}>
                        <img crossOrigin='anonymous' src="/icons/gonext.svg" width="15px" alt="" />
                        ثبت و مرحله بعد
                      </button>

                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddDetails