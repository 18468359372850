import { useState } from 'react';
import { CgChevronLeftR } from 'react-icons/cg';
import * as DialogShow from '../../../services/redux/dialog';
import { useDispatch, useSelector } from 'react-redux';
import * as BackdropShow from '../../../services/redux/backdrop';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import { detect_bank } from '../../../helpers/bankName';
import _ from 'lodash';
import { CircularProgress, Pagination } from '@mui/material';
import { getProfile } from '../../../services/redux/user';
import AddCard from '../../Profile/dialog/AddCard';
import CardDetail from '../../Profile/dialog/CardDetail';
import ConfirmUpgrade from '../dialogs/ConfirmUpgrade';
import useSnack from '../../../hooks/useSnack';
import FetchListLoad from '../../../components/FetchListLoad';
import NeedUpgrade from '../../../components/NeedUpgrade';

const AddAccountBank = ({ setChangeview }: any) => {
  const cardBanckList = useSelector((state: any) => state.user.cards);
  const profile = useSelector((state: any) => state.user.profile);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [totalPage, setTotalPage]: any = useState(1);
  const [page, setPage] = useState(1);
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const sendUpgrade = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/levelUp`).get();

    if (result.code === 200) {
      dispatch(DialogShow.show(<ConfirmUpgrade text={"تبریک ! شما هم اکنون  می توانید از ویژگی های سطح جدید استفاده کنید"} close={() => dispatch(DialogShow.hide())} />))
      await dispatch(getProfile());
      setChangeview("showAll");
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <div className="accountBank">
      <button className="addCard" onClick={() => {
        (profile.birthDate == null || !profile?.verify?.identity)
          ? dispatch(DialogShow.show2(<NeedUpgrade />))
          : dispatch(DialogShow.show(<AddCard sendUpgrade={sendUpgrade} isSendUpgrade={true} close={() => dispatch(DialogShow.hide())} />))
      }}>
        افزودن کارت بانکی
        <img crossOrigin='anonymous' src="/icons/Iconly-Bold-Plus.svg" alt="" />
      </button>
      <div className='tb_View tb_CardBank'>
        <table>
          <thead>
            <tr>
              <th><h5>نام بانک</h5></th>
              <th><h5>شماره کارت</h5></th>
              <th><h5>شماره شبا</h5></th>
              <th><h5>وضعیت</h5></th>
              <th><h5>جزئیات</h5></th>
            </tr >
          </thead >
          <tbody className={"relative"}>
            {!_.isEmpty(Object.values(cardBanckList))
              && Array.from(Object.values(cardBanckList))?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  <td>
                    <div className="nameBanck">
                      <img src={`${!_.isEmpty(detect_bank(i?.number).bank_logo) ? detect_bank(i?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
                      <span className="netdes">{i.bank}</span>
                    </div>
                  </td>
                  <td className='fontCard'>
                    {i?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}
                  </td>
                  <td>
                    {i?.iban}
                  </td>
                  <td>
                    <div className='btnControl'>
                      <span className='confirm'>تایید شد</span>
                      {/* <span className={`defaultCard ${i?.isDefault && "active"}`}>پیشفرض</span> */}
                    </div>
                  </td>
                  <td onClick={() => dispatch(DialogShow.show(<CardDetail data={i} close={() => dispatch(DialogShow.hide())} />))}>
                    <CgChevronLeftR size={25} />
                  </td>
                </tr>)}
          </tbody>
        </table >
        <FetchListLoad list={Object.values(cardBanckList)} load={false} />
      </div >

      <div className='paging'>
        <Pagination color="primary" page={page} count={totalPage === 0 ? 1 : totalPage} onChange={(_e: any, v: any) => setPage(v)} />
      </div>
      <br />
      <br />
      <div className='btn'>
        {
          myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button className="finalCheckBtn upbtn" onClick={() => {
              if (Object.values(cardBanckList)?.length == 0) {
                snackUse.showError("لطفا کارت بانکی خود را اضافه کنید");
                return
              }
              sendUpgrade();
            }}>
              درخواست ارتقا
            </button>
        }
      </div>
    </div>
  )
}

export default AddAccountBank