import React, { useState, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { config } from '../../../config/config'
import { menu } from '../../../config/menu'
import { Pagination } from '@mui/material'
import FetchListLoad from '../../../components/FetchListLoad'
import { CgChevronLeftR } from 'react-icons/cg'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'
import { crateQuerySearch, zeroFormat } from '../../../helpers/tools'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'jalali-moment'
import _ from 'lodash'
import { statusFA } from '../../../config/variable'
import * as DialogShow from '../../../services/redux/dialog'
import useSnack from '../../../hooks/useSnack'
import gather from '../../../helpers/gather'
import FilterWithID from './FilterWithID'
import DetailWDCoin from '../../withdrawAll/dialogs/DetailWDCoin'

const CoinHistory = ({ flag }: { flag: boolean }) => {
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const dispatch = useDispatch<any>();
  const snackUse = useSnack();
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [reloadApi, setReloadApi] = useState('');
  const [advancedSearch, setAdvancedSearch] = useState({
    fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
    toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
  });
  const listStatus = [{ name: "موفق", value: "confirm" },
  { name: "منتظر", value: "pending" },
  { name: "بررسی", value: "process" },
  { name: "رد شده", value: "reject" },
  ]

  const get = async (quList: any) => {
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/${flag ? "deposit" : "withdraw"}?sortBy=createdAt&orderBy=desc${crateQuerySearch(quList)}`).get()

    if (result.message === 200) {
      setListHistory(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  const createQuery = () => {
    let newListQuery = [...[{ key: "page", value: pageCurrent }], ...Object.values(advancedSearch)]
    get(newListQuery);
  }

  useEffect(() => {
    createQuery();
  }, [reloadApi])

  useEffect(() => {
    document.title = menu.financial.childs.transactionHistory.childs.depositCoin.title;
  }, []);

  return (
    <>
      <FilterWithID showCoin={true} listStatus={listStatus} showType={false}
        hanleSelect={(e: any) => {
          setAdvancedSearch(e); setTimeout(() => {
            setPageCurrent(1);
            setReloadApi(new Date().getTime().toString());
          }, 100);
        }}
        listCoinObj={listCoinObj} />
      <div className={`historyBoxAll allHistoryStyle`}>
        <div className='tb_View tb_Send'>
          <table>
            <thead>
              <tr>
                <th><h5>شماره پیگیری</h5></th>
                <th><h5>تاریخ و زمان</h5></th>
                <th><h5>رمز ارز</h5></th>
                <th><h5>مقدار</h5></th>
                <th><h5>شبکه</h5></th>
                <th><h5>کیف پول</h5></th>
                <th><h5>وضعیت</h5></th>
                <th><h5>هش تراکنش (TxID)</h5></th>
                <th><h5></h5></th>
              </tr >
            </thead >
            <tbody className={"relative"}>
              {(!loadHistory && !_.isEmpty(listHistory))
                && Array.from(listHistory)?.map((i: any, n: any) =>
                  <tr key={n} className="box">
                    <td>{i?.uniqueId}</td>
                    <td> <div className='time'>{moment(i?.createdAt).locale("fa").format('HH:mm | YYYY-MM-DD')}</div></td>
                    <td>
                      <div className='name right'>
                        <LazyLoadImage crossOrigin='anonymous' className='coin' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" />
                        <p>{i?.coin?.nameFa ?? "---"}</p>
                      </div>
                    </td>
                    <td>{zeroFormat(i?.amount)}&nbsp; <b>{i?.coin?.symbol?.toUpperCase()}</b></td>
                    <td>{i?.network?.name ?? "- - -"}</td>
                    <td>
                      {i?.sender &&
                        <div className='name'>
                          <CopyToClipboard text={i?.sender} onCopy={() => { i?.sender && snackUse.showSaccess("آدرس کپی شد") }}>
                            <span className='icon-IconCopy3 cPointer'></span>
                          </CopyToClipboard>
                          <p className='ltr font-14'>{i.sender?.length > 18 ? i.sender?.slice(0, 6) + ". . ." + i.sender?.slice(-6, i.sender?.length) : i.sender}</p>
                        </div>}
                    </td>
                    <td><span className={`status ${i?.status}`}>{statusFA[i?.status]}</span></td>
                    <td>
                      {i?.hash &&
                        <div className='name'>
                          <NavLink to={i?.network?.scanner + "/" + i?.hash} target="_blank" ><span className='icon-IconLink2 cPointer'></span></NavLink>
                          <CopyToClipboard text={i?.hash} onCopy={() => { i?.hash && snackUse.showSaccess("هش تراکنش کپی شد") }}>
                            <span className='icon-IconCopy3 cPointer'></span>
                          </CopyToClipboard>
                          <p className='ltr'>{i?.hash?.length > 18 ? i?.hash?.slice(0, 6) + ". . ." + i?.hash?.slice(-6, i?.hash?.length) : i?.hash}</p>
                        </div>}
                    </td>
                    <td className='cPointer' onClick={() => dispatch(DialogShow.show(<DetailWDCoin data={i} deposit={true} close={() => dispatch(DialogShow.hide())} />))}>
                      <CgChevronLeftR size={25} />
                    </td>
                  </tr>)}
            </tbody>
          </table >
          <FetchListLoad list={listHistory} load={loadHistory} />
        </div >
        <div className='paging'>
          <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReloadApi(new Date().getTime().toString()) }} />
        </div>
      </div>
    </>
  )
}

export default memo(CoinHistory)