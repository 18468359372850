import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CgChevronLeftR } from 'react-icons/cg'
import { useDispatch } from 'react-redux';
import { CircularProgress, Pagination } from '@mui/material';
import { config } from '../../../config/config';
import gather from '../../../helpers/gather';
import { ticketStatusFA } from '../../../config/variable';
import moment from 'jalali-moment';
import { findDateQuery } from '../../../helpers/tools';
import { NavLink, useNavigate } from 'react-router-dom';
import { menu } from '../../../config/menu';
import _ from 'lodash';
import * as DataSaver from '../../../services/redux/dataSaver';
import useSnack from '../../../hooks/useSnack';
import FetchListLoad from '../../../components/FetchListLoad';

const HistoryTicket = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [clickFilter, setClickFilter] = useState("all");
  const [loadHistory, setLoadHistory] = useState(true);
  const [listTicket, setListTicket] = useState([]);
  const [totalPage, setTotalPage]: any = useState(1);
  const [page, setPage] = useState(1);
  const snackUse = useSnack();

  const goToChat = (i: any) => {
    dispatch(DataSaver.addTicketChat(i))
    navigate(menu.ticket.childs.chat.url)
  }

  const get = async () => {
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/ticket?sortBy=createdAt&orderBy=desc${findDateQuery({ status: clickFilter }, false, true)}`).get();
    // const result2 = await gather(`${config.api}/v1/ticket?id=6636ae243e7645c344856442`).get();
    //asc
    if (result.message === 200) {
      setListTicket(result?.data ?? []);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  useEffect(() => {
    get();
  }, [clickFilter])


  return (
    <div className='historyTicket'>
      <h3>قبل از ارسال تیکت به بخش پشتیبانی نکات زیر را مطالعه کنید</h3>
      <div className='description'>
        <p> لطفا از ارسال تیکت تکراری خودداری کنید زیرا پشتیبانی سایت به اولین تیکت در انتظار شما پاسخ خواهد داد.</p>
        <p> تنها برای پیگیری سفارشات ) در صورت عدم واریز طی ۲۴ ساعت گذشته روز کاری ( و مسائل فنی هنگام خرید ، فروش و سایر مشکلات تیکت ارسال کنید</p>
        <p> زمان پاسخگویی به تیکت ها در ساعات و روز های کاری تا حداکثر ۲ ساعت و در روز های تعطیلی تا حداکثر ۵ ساعت می باشد</p>
      </div>
      <div className='filterTable'>
        <div className={`item ${clickFilter == "all" && "active"} `} onClick={() => setClickFilter("all")}>
          <p>همه</p>
          <span>0</span>
        </div>
        <div className={`item ${clickFilter == "pending" && "active"} `} onClick={() => setClickFilter("pending")}>
          <p>تیکت باز</p>
          <span>0</span>
        </div>
        <div className={`item ${clickFilter === "answer" && "active"} `} onClick={() => setClickFilter("answer")}>
          <p>پاسخ داده شده</p>
          <span className='none'></span>
        </div>
        <div className={`item ${clickFilter === "close" && "active"} `} onClick={() => setClickFilter("close")}>
          <p>بسته شده</p>
          <span className='none'></span>
        </div>

        <NavLink to={menu.ticket.childs.create.url} className="addTicket">
          <p>تیکت جدید</p>
        </NavLink>
      </div>
      <hr className='divideFilterT' />
      <div className='tb_View tb_Ticket'>
        <table>
          <thead>
            <tr>
              <th><h5>شماره تیکت</h5></th>
              <th><h5>موضوع تیکت</h5></th>
              <th><h5>بخش</h5></th>
              <th><h5>وضعیت</h5></th>
              <th><h5>آخرین پاسخ</h5></th>
              <th><h5>نمایش</h5></th>
            </tr >
          </thead >
          <tbody className={"relative"}>
            {(!loadHistory && !_.isEmpty(listTicket))
              && Array.from(listTicket)?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  <td onClick={() => goToChat(i)}> {i?.uniqueId}</td>
                  <td onClick={() => goToChat(i)}>{i?.title} </td>
                  <td>پشتیبانی</td>
                  <td><span id={i?.status}>{ticketStatusFA[i?.status]}</span></td>
                  <td>{moment(i?.createdAt).locale("fa").format('HH:mm - YYYY-MM-DD')}</td>
                  {/* <td onClick={() => dispatch(DialogShow.show(<DetailTicket data={i} />))}> */}
                  <td onClick={() => goToChat(i)}><CgChevronLeftR size={25} /></td>
                </tr>)}
          </tbody>
        </table >
        <FetchListLoad list={listTicket} load={loadHistory} />
      </div >

      {/* <div className='pagination'>
        <button className='finalCheckBtn'>
          <div className='imgArrowCircle'><span className='icon-Arrow2'></span></div>
          <p>صفحه قبلی</p>
        </button>
        <Pagination color="primary"  count={4} hidePrevButton hideNextButton />
      </div> */}
      <div className='paging'>
        <Pagination color="primary" page={page} count={totalPage === 0 ? 1 : totalPage} onChange={(_e: any, v: any) => setPage(v)} />
      </div>

    </div>
  )
}

export default HistoryTicket