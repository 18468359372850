import { Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../../../config/config';
import { findDateQuery, zeroFormat } from '../../../../helpers/tools';
import gather from '../../../../helpers/gather';
import _ from 'lodash';
import { orderStatusFA, sideFA, typeOrderFA } from '../../../../config/variable';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'jalali-moment';
import useSnack from '../../../../hooks/useSnack';
import FetchListLoad from '../../../../components/FetchListLoad';
import { CgChevronLeftR } from 'react-icons/cg';
import * as DialogShow from '../../../../services/redux/dialog';
import DetailOrders from '../../dialog/DetailOrders';
import { socket } from '../../../../services/socket';
import * as DataSaver from '../../../../services/redux/dataSaver';

const History = ({ reload, setReload }: { reload: any, setReload: any }) => {
  const dispatch = useDispatch<any>();
  const listVoucherObjID = useSelector((state: any) => state.publicApis?.listVoucherObjID);
  const { t } = useTranslation();
  const [clickFilter, setClickFilter] = useState("pending");
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory]: any = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));
  const snackUse = useSnack();
  const [objSocket, setObjSocket]: any = useState({});
  const orderSocketData = useSelector((state: any) => state.dataSaver?.orderSocketData);

  const deleteOrder = async (id: any) => {
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/order/${id}`).delete();

    if (result.message === 200) {
      snackUse.showSaccess("سفارش با موفقیت لغو شد");
      get();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  const get = async () => {
    setListHistory([])
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/order?sortBy=createdAt&orderBy=desc&page=${pageCurrent}&fromDate=${from}&toDate=${to}&-coins=${Object.keys(listVoucherObjID).toString().replaceAll(",", "-") ?? ""}${clickFilter == "pending" ? findDateQuery({ status: "pending-waiting" }, false, true) : findDateQuery({ "-status": "pending-waiting" }, false, true)}`).get();

    if (result.message === 200) {

      setListHistory(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  useEffect(() => {
    get();
  }, [reload]);

  const changeList = () => {
    if (!listHistory) {
      get();
      return
    }
    const newList: any = _.cloneDeep(listHistory)
    const index: any = Array.from(newList)?.findIndex((e: any) => e?._id == objSocket?.id);

    if (index != -1 && newList[index] && objSocket?.status == "pending") {
      newList[index].fill = objSocket?.fill;
      newList[index].status = objSocket?.status;
      setListHistory(newList)
    } else {
      get();
    }
  }

  useEffect(() => {
    changeList()
  }, [objSocket]);

  const handleSocket = (result: any) => {
    setObjSocket(result);
  }

  useEffect(() => {
    handleSocket(orderSocketData)
    // socket?.on('order', handleSocket);
    // return () => {
    //   socket?.removeListener('order', handleSocket);
    // };
  }, [orderSocketData]);


  return (
    <div className='newHistoryOrder'>
      <div className='tb_opratorBox'>
        <div className='btnFilter'>
          <div className={`item ${clickFilter == "pending" && "active"}`} onClick={() => { setClickFilter("pending"); setReload(new Date().getTime().toString()) }}>
            <span className='icon-IconPaste'></span>
            <p>سفارشات باز</p>
          </div>
          <div className={`item ${clickFilter == "all" && "active"}`} onClick={() => { setClickFilter("all"); setReload(new Date().getTime().toString()) }}>
            <span className='icon-IconNote'></span>
            <p>تاریخچه تبادلات</p>
          </div>
        </div>
        <div className='refreshAndInfo'>
          {/* <div className='info'>
                  <p>هنوز واریز نشده؟</p>
                  <span className='icon-IconInfoCircle'></span>
                </div> */}
          <button className='refresh' onClick={() => get()}>
            <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconRefresh.svg" alt="" />
            <p>بروزرسانی</p>
          </button>
        </div>
      </div>
      <div className='tb_View tb_OrderFast'>
        <table>
          <thead>
            <tr>
              {/* <th><h5>شماره سفارش</h5></th> */}
              <th><h5>بازار</h5></th>
              <th><h5>سمت</h5></th>
              <th><h5>نوع</h5></th>
              <th><h5>قیمت</h5></th>
              <th><h5>مقدار</h5></th>
              {/* <th><h5>مقدار تبادل</h5></th> */}
              <th><h5>مجموع</h5></th >
              <th><h5>زمان</h5></th >
              <th><h5>وضعیت</h5></th >
              <th><h5 className='colorRed'></h5></th >
              <th></th>
              <th></th>
            </tr >
          </thead >
          <tbody className={"relative"}>
            {(!loadHistory && !_.isEmpty(listHistory))
              && Array.from(listHistory)?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  {/* <td>{i?.uniqueId}</td> */}
                  <td>
                    <div id='name'>
                      <div className='imgPair'>
                        {/* <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" /> */}
                        <LazyLoadImage crossOrigin='anonymous' className='coin' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" />
                        <LazyLoadImage crossOrigin='anonymous' className='pai' src="/icons/iranflag.svg" alt="" />
                      </div>
                      <p>{i?.pair?.toUpperCase()}/</p>
                      <h5>{i?.coin?.symbol?.toUpperCase() ?? "---"}</h5>
                    </div>
                  </td>
                  <td className={`color-${i?.side}`}>{sideFA[i?.side]}</td>
                  <td>{typeOrderFA[i?.trade]}</td>
                  <td>{zeroFormat(i?.price)} <b>{i?.pair} </b></td>
                  <td>{zeroFormat(i?.amount)} <b>{i?.coin?.symbol?.toUpperCase()} </b></td>
                  {/* <td>{zeroFormat(i?.fill)}</td> */}
                  <td>{zeroFormat(i?.total)} <b>{i?.pair}</b></td>
                  <td>{i?.createdAt ? moment(i?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD') : "---"}</td>
                  <td><div id={i?.status}>{orderStatusFA[i?.status]}</div></td>
                  {(i?.status == "pending" || i?.status == "waiting")
                    && <td>
                      <div id='cancleOrder' onClick={() => deleteOrder(i?._id)}>
                        <LazyLoadImage src="/icons/m-BinRed.svg" alt="" />
                        <p>لغو سفارش</p>
                      </div>
                    </td>}
                  <td>
                    <CgChevronLeftR size={25}
                      className='cPointer btnDetail'
                      onClick={() => dispatch(DialogShow.show(<DetailOrders data={i} deposit={i?.side == "buy" ? true : false} close={() => dispatch(DialogShow.hide())} />))}
                    />
                  </td>
                </tr>)}
          </tbody>
        </table >
        <FetchListLoad list={listHistory} load={loadHistory} />
      </div >
      <div className='paging'>
        <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReload(new Date().getTime().toString()) }} />
      </div>
    </div >
  )
}

export default History