import { createSlice } from "@reduxjs/toolkit"

const stateBackdrop: { [key: string]: any } = {
  open: false,
  openCircle: false,
}

export const BackdropShow = createSlice({
  name: "backdropState",
  initialState: stateBackdrop,
  reducers: {
    show: (state) => {
      state.open = true;
    },
    hide: (state) => {
      state.open = false
    },

    showCircle: (state) => {
      state.openCircle = true;
    },
    hideCircle: (state) => {
      state.openCircle = false
    }
  }
})


export const { show, hide,showCircle ,hideCircle} = BackdropShow.actions;
export default BackdropShow.reducer;
