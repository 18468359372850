import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import { formatter, zeroFormat } from '../../helpers/tools';
import DatePicker, { DateObject } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CircularProgress, Pagination, Skeleton, Stack } from '@mui/material';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import useSnack from '../../hooks/useSnack';
import { useTranslation } from 'react-i18next';
import moment from 'jalali-moment';
import { statusGiftFA } from '../../config/variable';
import CopyToClipboard from 'react-copy-to-clipboard';
import ItemTotalTmn from './item/ItemTotalTmn';
import SelectCoinGift from './item/SelectCoinGift';
import * as BackdropShow from '../../services/redux/backdrop';

const Gift = () => {
  const dispatch = useDispatch<any>();
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const [coinSelectError, setCoinSelectError] = useState(false);
  const [listAlert, setListAlert]: any = useState([]);
  const [reset, setReset] = useState('');
  const [listCoin, setListCoin]: any = useState({});
  const [valueTime, setValueTime]: any = useState("");
  const [myLoadingUse, setMyLoadingUse] = useState(false);
  const [myLoading, setMyLoading] = useState(false);
  const [loadHistory, setLoadHistory] = useState(true);
  const [codeGift, setCodeGift] = useState("");
  const [mobile, setMobile] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [listSelect, setListSelect] = useState({});
  const snackUse = useSnack();
  const { t } = useTranslation();
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [reloadApi, setReloadApi] = useState("");

  const handleListcoin = () => {
    const raw: any = _.cloneDeep(listCoinObj);

    const allowed = Object.keys(mainWalletObj).filter(e => mainWalletObj[e].amount > 0);

    const filtered = Object.keys(raw)
      .filter(key => allowed.includes(key))
      .reduce((obj: any, key: any) => {
        raw[key]["balance"] = `${zeroFormat(mainWalletObj[key]?.free)} : موجودی`
        obj[key] = raw[key];
        return obj;
      }, {});


    if (mainWalletObj["tmn"]?.free) {
      filtered["tmn"] = { _id: "tmn", nameFa: "تومان", symbol: "tmn", balance: ` ${zeroFormat(mainWalletObj["tmn"]?.free)} : موجودی` }
    }

    setListCoin(filtered ?? {})
  }

  const useGift = async () => {
    if (codeGift.trim().length === 0) {
      snackUse.showError("کد هدیه خود را وارد کنبد");
      return
    }

    setMyLoadingUse(true);

    const result = await gather(`${config.api}/v1/gift/use`).post({
      "code": codeGift,
    });

    if (result.message === 200) {
      snackUse.showSaccess("کد هدیه فعال شد");
      setCodeGift("")
      get();
    } else {
      snackUse.showError(result.message == 10038 ? "کد هدیه صحیح نیست" : t(result.message) || t("99999"));
    }

    setMyLoadingUse(false);
  }

  const createGift = async () => {
    if (title?.trim().length == 0) {
      snackUse.showError("عنوان را وارد کنبد");
      return
    }

    if (Object.keys(listSelect)?.length <= 0) {
      snackUse.showError("لطفا ارز هدیه را وارد کنید");
      setCoinSelectError(true)
      setTimeout(() => { setCoinSelectError(false) }, 3000)
      return;
    }

    if (mobile.trim().length === 0) {
      snackUse.showError("موبایل دریافت کننده را وارد کنبد");
      return
    }

    if (valueTime == "") {
      snackUse.showError("تاریخ انقضا را وارد کنبد");
      return
    }

    setMyLoading(true);

    const newObj = _.cloneDeep(listSelect)
    Object.values(newObj)?.map((i: any, n: any) =>
      delete i?.name
    )


    const result = await gather(`${config.api}/v1/gift`).post({
      "data": Object.values(newObj),
      "expire": valueTime,
      "mobile": mobile,
      "title": title,
      "text": text,
    });

    if (result.message === 200) {
      snackUse.showSaccess("کد هدیه با موفقیت ایجاد شد");
      setValueTime("");
      setMobile("");
      setText("");
      setTitle("");
      setReset(new Date().getTime().toString());
      setReloadApi(new Date().getTime().toString());
      setListSelect({})
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const handleActive = async (item: any) => {

    if (item?.status != "pending") {
      snackUse.showError("کارت هدیه منقضی یا مصرف شده است");
      return
    }

    dispatch(BackdropShow.showCircle());

    const result = await gather(`${config.api}/v1/gift/${item?._id}`).put({
      "isActive": !item?.isActive,
    });

    if (result.message === 200) {
      snackUse.showSaccess("وضعیت ثبت شد");
      get()
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hideCircle());
  }



  const get = async () => {
    setListAlert([]);
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/gift?sortBy=createdAt&orderBy=desc`).get()

    if (result.message === 200) {
      setListAlert(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  useEffect(() => {
    get();
  }, [reloadApi]);

  useEffect(() => {
    handleListcoin()
    document.title = menu.giftCode.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="giftBody">
            <div className="creatGift useGift">
              <div className='eleBox'>
                <div className='priceBox'>
                  <div className='title'>
                    <span className='icon-IconGift2'></span>
                    <p>استفاده از کارت هدیه</p>
                  </div>
                  <p className='p1'>کد کارت هدیه را وارد کنید</p>
                  <div className='inputBox'>
                    <input type="text" value={codeGift} onChange={(e: any) => setCodeGift(e.target.value)} placeholder='کد کارت هدیه' />
                  </div>

                  <p className='p1 p2'>با وارد کردن کد کارت هدیه میتوانید از مزایای آن بهره‌مند شوید.</p>
                  {myLoadingUse
                    ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                    : <button onClick={useGift} className="subBtn">فعالسازی</button>}
                </div>
              </div>
              <div className='imgBox'>
                <img src="/images/gift-shopping.png" alt="" />
              </div>
            </div>

            <div className="creatGift">
              <div className='eleBox'>
                <div className='priceBox'>
                  <div className='title'>
                    <span className='icon-IconAddCard'></span>
                    <p>ساخت کارت هدیه جدید</p>
                  </div>
                  <p className='p1'>عنوان</p>
                  <div className='inputBox'>
                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder='عنوان' />
                  </div>
                  <p className='p1'>شماره موبایل دریافت کننده هدیه</p>
                  <div className='inputBox'>
                    <input type="tel" className='tel' value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='شماره موبایل دریافت کننده' />
                  </div>
                  <p className='p1'>انتخاب ارز</p>
                  <SelectCoinGift classNamePopup={""}
                    error={coinSelectError} listData={listCoin ?? {}} listSelect={listSelect}
                    setListSelect={(e: any) => setListSelect(e)} reset={reset} className={""} />
                  <p className='p1'>تاریخ انقضای هدیه</p>
                  <div className='inputBox'>
                    <DatePicker
                      value={valueTime}
                      onChange={(e: any) => setValueTime(e?.unix * 1000)}
                      calendar={persian}
                      locale={persian_fa}
                      minDate={new DateObject({ calendar: persian })}
                      calendarPosition="bottom-right"
                      placeholder='تاریخ انقضا'
                    />
                  </div>
                  <p className='p1'>توضیحات</p>
                  <div className='inputBox'>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder='توضیحات' />
                  </div>
                  {myLoading
                    ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                    : <button onClick={createGift} className="subBtn">ایجاد کارت هدیه</button>}
                </div>
              </div>
              <div className='imgBox'>
                <img src="/images/gift.png" alt="" />
              </div>
            </div>
          </div>
          <div className='historyAlert historyGift'>
            <div className='title'>
              <span className='icon-IconList'></span>
              <p>لیست کارت هدیه ها</p>
            </div>
            <div className='listBox'>
              {(!loadHistory && !_.isEmpty(listAlert))
                && Array.from(listAlert)?.map((i: any, n: any) =>
                  <div className='item' key={n}>
                    <div className='topTitle'>
                      <div className='itemTitle'>
                        <div className='txBox'>
                          <span className='icon-IconGift20'></span>
                          <p className='t1'>{i?.text?.slice(0, 30) ?? "کارت هدیه"}</p>
                        </div>
                        <p className='t1 t2'>{i?.title?.slice(0, 54) ?? "---"}</p>
                      </div>
                      {/* <div className='infoBox' onClick={() => handleActive(i)}>
                        <span className='iconInfo'></span>
                        <p className={`t1 ${i?.isActive ? "colorGreen" : "colorRed"}`}>{i?.isActive ? "فعال" : "غیرفعال"}</p>
                      </div> */}
                    </div>
                    <div className='divider'></div>
                    {Array.from(i?.data)?.map((i: any, n: any) =>
                      <div className='txtBox' key={n}>
                        {i?.fiat
                          ? <>
                            <LazyLoadImage crossOrigin='anonymous' className='coinIcon' src={`/icons/iranflag.svg`} alt="" />
                            <p className='p1'>{formatter(i?.amount, 0, 0, true)} &nbsp; TMN</p>
                          </>
                          : <>
                            <LazyLoadImage crossOrigin='anonymous' className='coinIcon' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase() ?? "x"}.png`} alt="" />
                            <p className='p1'>{zeroFormat(i?.amount)} &nbsp; {i?.coin?.nameFa ?? "---"}</p>
                          </>
                        }
                      </div>
                    )}

                    <div className='txtBox'>
                      <p className='p1 gray'>مجموع</p>
                      <ItemTotalTmn data={i?.data} />
                    </div>

                    <div className='txtBox'>
                      <p className='p1 gray'>موبایل</p>
                      <p className='p1 '>{i?.mobile}</p>
                    </div>
                    <div className='txtBox'>
                      <p className='p1 gray'>کد هدیه</p>
                      <div className='txtCode'>
                        <CopyToClipboard text={i?.code} onCopy={() => { i?.code && snackUse.showSaccess("کد هدیه  کپی شد") }}>
                          <span className='icon-IconCopy16 cPointer'></span>
                        </CopyToClipboard>
                        <p className='p1 fontBold'>{i?.code}</p>
                      </div>
                    </div>
                    <div className='txtBox'>
                      <p className='p1 gray'>وضعیت</p>
                      <p className='p1' id={i?.status}>{statusGiftFA[i?.status]}</p>
                    </div>
                    {/* <div className='txtBox'>
                      <p className='p1 gray'>سمت</p>
                      <p className={`p1 ${i?.receiver ? "colorRed" : i?.side == "send" ? "colorGreen" : "colorRed"}`}>{i?.receiver ? "دریافت شد" : sideFA[i?.side]}</p>
                    </div> */}
                    {i?.receiver
                      ? <div className='txtBox'>
                        <p className='p1 coin'>تاریخ فعالسازی</p>
                        <p className='p1 colorGreen'>{moment(i?.updatedAt).locale("fa").format('HH:mm | YYYY-MM-DD')}</p>
                      </div>
                      :
                      <div className='txtBox'>
                        <p className='p1 red'>تاریخ انقضا</p>
                        <p className='p1 red'>{moment(i?.expire).locale("fa").format('HH:mm | YYYY-MM-DD')}</p>
                      </div>
                    }

                  </div>
                )}
              <div className='fetchListLoad'>
                {loadHistory
                  ? <div className='emptyLoad'>
                    {new Array(3)?.fill("a").map((i, n) =>
                      <Stack key={n} spacing={1} style={{ width: "30%" }}>
                        <Skeleton sx={{ height: '295px' }} />
                      </Stack>
                    )}
                  </div>
                  : _.isEmpty(listAlert)
                  && <div className='emptyLoad'>
                    <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" />
                    <p>موردی یافت نشد</p>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='paging'>
            <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => { setPageCurrent(v); setReloadApi(new Date().getTime().toString()) }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Gift