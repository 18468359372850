import { useEffect, useRef, useState } from 'react';
import _ from "lodash";
import ListTextCheckBox from './ListTextCheckBox';

const MultiSelectBoxLabel = ({ setInputSatet, listData, className, error, reset, classNamePopup = "" }: any) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [firstTime, setFirstTime]: any = useState(false);
  const [selectCoin, setSelectCoin]: any = useState({});
  const [resetCheck, setResetCheck]: any = useState('');

  const handleNamesSelect = () => {
    if (Object.values(selectCoin)?.filter((e: any) => e.isChecke == true).length > 0) {
      if (Object.values(selectCoin)?.filter((e: any) => e.isChecke == true)?.length === 1) {
        const myList: any = Object.values(selectCoin)?.filter((e: any) => e.isChecke == true)
        return myList?.[0]?.detail?.name
      } else {
        return Object.values(selectCoin)?.filter((e: any) => e.isChecke == true)?.length+" " + "  وضعیت  " 
      }
    } else {
      return "انتخاب وضعیت"
    }
  }

  const handleSend = () => {
    if (Object.values(selectCoin)?.filter((e: any) => e.isChecke == true).length > 0) {
      let newItem: any = []
      Object.values(selectCoin)?.filter((e: any) => e.isChecke == true)?.forEach((element: any) => {
        element?.isChecke && newItem.push(element?.myId)
      });

      setInputSatet(newItem?.join('-'))
    } else {
      setInputSatet("")
    }
  }

  useEffect(() => {
    handleSend()
  }, [selectCoin]);

  useEffect(() => {
    if (firstTime) {
      setSelectCoin({});
      setResetCheck(new Date().getTime().toString());
    } else {
      setFirstTime(true);
    }
  }, [reset])

  return (
    <div className={`customInput customInputSelect multiSelectBoxSearch ${className} ${error && "brRed"}`} >
      {/* <ClickAwayListener onClickAway={handleClose}> */}
      <div className='textViewBox' onClick={() => setOpen(!open)}>
        <div className='nameBoxSelect'>
          <p className='nameoinSelect'>{handleNamesSelect()}</p>
          {Object.values(selectCoin)?.filter((e: any) => e.isChecke == true).length  > 0 && <span className="cPointer"
            onClick={() => { setResetCheck(new Date().getTime().toString()); setSelectCoin({}); }}>X</span>}
        </div>
        <button ref={anchorRef} className='btnPopUp mr-auto' ><span className="icon-Arrow2 cPointer"></span></button>
      </div>
      {/* </ClickAwayListener> */}
      <ListTextCheckBox className={`${classNamePopup} ${open ? "" : "none"}`}
        setSelectCoin={setSelectCoin} selectCoin={selectCoin}
        listData={listData}
        reset={resetCheck} />

    </div >
  )
}

export default MultiSelectBoxLabel;