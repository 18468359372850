import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import CustomSelectBoxSearch from '../../components/CustomSelectBoxSearch';
import { NumericFormat } from 'react-number-format';
import { autoFormatter, removeComma, toEnglishDigits } from '../../helpers/tools';
import DatePicker,{ DateObject } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CircularProgress, Skeleton, Stack } from '@mui/material';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import useSnack from '../../hooks/useSnack';
import { useTranslation } from 'react-i18next';
import CustomSelectBoxLabel from '../../components/CustomSelectBoxLabel';
import ItemChart from './item/ItemChart';
import ItemPriceCoin from './item/ItemPriceCoin';
import CustomInput from '../../components/CustomInput';
import ItemPriceNow from './item/ItemPriceNow';
import moment from 'jalali-moment';


const AlertCoin = () => {
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const [coinSelectError, setCoinSelectError] = useState(false);
  const [listAlert, setListAlert]: any = useState([]);
  const [reset, setReset] = useState('');
  const [coinSelect, setCoinSelect]: any = useState({});
  const [price, setPrice] = useState(0);
  const [valueTime, setValueTime]:any = useState("");
  const [pairSwitch, setPairSwitch] = useState("tmn");
  const [myLoading, setMyLoading] = useState(false);
  const [loadHistory, setLoadHistory] = useState(true);
  const [priceError, setPriceError] = useState(false);
  const snackUse = useSnack();
  const { t } = useTranslation();

  const hanleSelect = (e: any) => {
    setCoinSelect(e);
    setPrice(0);
  }

  const send = async () => {
    if (price <= 0) {
      snackUse.showError("قیمت هدف را وارد کنبد");
      setPriceError(true)
      setTimeout(() => { setPriceError(false) }, 3000)
      return
    }

    if (_.isEmpty(coinSelect)) {
      snackUse.showError("لطفا ارز خود را وارد کنید");
      setCoinSelectError(true)
      setTimeout(() => { setCoinSelectError(false) }, 3000)
      return;
    }

    if (valueTime == "") {
      snackUse.showError("تاریخ انقضا را وارد کنبد");
      return
    }

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/alert`).post({
      "coin": coinSelect?._id,
      "pair": pairSwitch,
      "price": Number(toEnglishDigits(price?.toString())),
      "expire": valueTime,
    });

    if (result.message === 200) {
      snackUse.showSaccess("هشدار  قیمت با موفقیت ثبت شد");
      setCoinSelect({});
      setValueTime("");
      setPrice(0);
      setReset(new Date().getTime().toString());
      get();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const deleteAlert = async (id: any) => {
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/alert/${id}`).delete();

    if (result.message === 200) {
      snackUse.showSaccess("هشدار قیمت با موفقیت حذف شد");
      get();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setLoadHistory(false);
    }

  }

  const get = async () => {
    setListAlert([]);
    setLoadHistory(true);

    const result = await gather(`${config.api}/v1/alert?sortBy=createdAt&orderBy=desc`).get()

    if (result.message === 200) {
      setListAlert(result?.data ?? []);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }


  useEffect(() => {
    document.title = menu.alertCoin.title;
    get();
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="alertBody">
            <div className='coinAlert'>
              <div className='title'>
                <span className='icon-IconGraph'></span>
                <p>اطلاعات بازار</p>
              </div>
              <div className='divider' ></div>
              <p className='p1'>ابتدا نوع هشدار را انتخاب کنید</p>
              <CustomSelectBoxLabel flag={false} element={
                <div className='showBanck'>
                  {true
                    ? <><h4 className='p-0' >هشدار قیمت</h4>
                      {/* <img className='m-0' src="" height="22px" alt="" /> */}
                    </>
                    : <h4 className='defaultText'><b>نوع هشدار</b></h4>}
                </div>}
                listData={[{ name: "هشدار قیمت", icon: "" }]} setInputSatet={() => { }} className={""} />
              <p className='p1'>نوع بازار و رمزارز  را انتخاب کنید</p>
              <div className='btnsPairs'>
                <button className={`btn ${pairSwitch == "usdt" && "active"}`} onClick={() => setPairSwitch("usdt")}>تتر</button>
                <button className={`btn ${pairSwitch == "tmn" && "active"}`} onClick={() => setPairSwitch("tmn")}>تومان</button>
              </div>
              <CustomSelectBoxSearch classNamePopup={"listCoinPopupV2"}
                error={coinSelectError} listData={listCoinObj ?? []} setInputSatet={hanleSelect} defalutText={coinSelect?.nameFa ?? "انتخاب بازار"}
                defalteImg={coinSelect ?? {}} reset={reset} className={""} />

              {!_.isEmpty(coinSelect) &&<ItemPriceNow symbol={coinSelect?.symbol} pair={pairSwitch}coinSelect={coinSelect}/> }


            </div>
            <div className='priceAlert'>
              {!_.isEmpty(coinSelect)
                && <div className='chartCoin'>
                  <ItemChart symbol={coinSelect?.symbol?.toLocaleUpperCase()} />

                  {/* <iframe className='iframeTradingViewWidget' src={coinSelect?.symbol?.toLocaleUpperCase() !== "USDT"
                    ? `https://www.tradingview-widget.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22BINANCE%3A${coinSelect?.symbol?.toLocaleUpperCase() + "USDT"}%7C1M%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Atrue%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Atrue%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22no-values%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22headerFontSize%22%3A%22medium%22%2C%22lineWidth%22%3A2%2C%22lineType%22%3A2%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22lineColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%201)%22%2C%22topColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%200)%22%2C%22bottomColor%22%3A%22rgba(144%2C%20191%2C%20249%2C%200.15)%22%2C%22utm_source%22%3A%22exnovin.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22exnovin.net%2Fwidget-wizard%2Fen%2Flight%2Fsymbol-overview%2F%22%7D`
                    : `https://www.tradingview-widget.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22BINANCEUS%3AUSDTUSD%7C1M%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Atrue%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Atrue%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22no-values%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22headerFontSize%22%3A%22medium%22%2C%22lineWidth%22%3A2%2C%22lineType%22%3A2%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22lineColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%201)%22%2C%22topColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%200)%22%2C%22bottomColor%22%3A%22rgba(144%2C%20191%2C%20249%2C%200.15)%22%2C%22utm_source%22%3A%22exnovin.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22exnovin.net%2Fwidget-wizard%2Fen%2Flight%2Fsymbol-overview%2F%22%7D`}>
                  </iframe> */}

                </div>}
              <div className='priceBox'>
                <div className='title'>
                  <span className='icon-IconAddBell'></span>
                  <p>ایجاد هشدار جدید</p>
                </div>
                <div className='divider'></div>
                <p className='p1'>قیمت هدف</p>
                <CustomInput error={priceError} inputState={price} setInputSatet={setPrice} label={""}
                  classStart={""} startEle={<p className='colorGray '>{pairSwitch?.toUpperCase()}</p>} endEle={""} className={"ltr"} placeholder={"قیمت هدف را مشخص کنید"} />
                <div className='inputBox'>
                  <DatePicker
                    value={valueTime}
                    onChange={(e: any) => setValueTime(e?.unix * 1000)}
                    calendar={persian}
                    locale={persian_fa}
                    minDate={new DateObject({ calendar: persian })}
                    calendarPosition="bottom-right"
                    placeholder='تاریخ پایان'
                  />
                </div>
                {myLoading
                  ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                  : <button onClick={send} className="subBtn">ایجاد هشدار</button>}
              </div>
            </div>
          </div>
          <div className='historyAlert'>
            <div className='title'>
              <span className='icon-IconList'></span>
              <p>لیست هشدارها</p>
            </div>
            <div className='listBox'>
              {(!loadHistory && !_.isEmpty(listAlert))
                && Array.from(listAlert)?.map((i: any, n: any) =>
                  <div className='item' key={n}>
                    <LazyLoadImage onClick={() => deleteAlert(i?._id)}
                      crossOrigin='anonymous' className='deleteIcon' src="/icons/m-BinRed.svg" alt="" />
                    <div className='divider'></div>
                    <div className='txtBox'>
                      <p className='p1'>نام ارز</p>
                      <p className='p1 coin'>{i?.coin?.symbol?.toLocaleUpperCase()}/{i?.pair?.toLocaleUpperCase()}</p>
                    </div>
                    <div className='txtBox'>
                      <p className='p1 gray'>قیمت فعلی</p>
                      <ItemPriceCoin pair={i?.coin?.symbol + i?.pair} />
                    </div>
                    <div className='txtBox'>
                      <p className='p1 red'>پایان در تاریخ</p>
                      <p className='p1 red'>{i?.expire?moment(i?.expire).locale("fa").format('HH:mm | YYYY-MM-DD'):"---"}</p>
                    </div>
                    <div className='txtBox'>
                      <p className='p1 gray'>قیمت هدف</p>
                      <p className='p1 '>{autoFormatter(i?.price)} &nbsp;{i?.pair == "usdt" ? "تتر" : "تومان"}</p>
                    </div>
                    <div className='txtBox'>
                      <p className='p1 gray'>وضعیت</p>
                      <p className={`p1 ${i?.isActive?"colorGreen":"red"}`}>{i?.isActive?"فعال":"غیر فعال"}</p>
                    </div>
                  </div>
                )}
              <div className='fetchListLoad'>
                {loadHistory
                  ? <div className='emptyLoad'>
                    {new Array(3)?.fill("a").map((i, n) =>
                      <Stack key={n} spacing={1} style={{ width: "30%" }}>
                        <Skeleton sx={{ height: '195px' }} />
                      </Stack>
                    )}
                  </div>
                  : _.isEmpty(listAlert)
                  && <div className='emptyLoad'>
                    <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" />
                    <p>موردی یافت نشد</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AlertCoin