import _ from 'lodash';
import CustomSelectBoxSearch from '../../../components/CustomSelectBoxSearch';
import { useEffect, useState } from 'react';
import DatePicker, { DateObject } from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import CustomSelectBoxLabel from '../../../components/CustomSelectBoxLabel';
import moment from 'jalali-moment';
import MultiSelectBoxSearch from '../../../components/MultiSelectBoxSearch';
import MultiSelectBoxLabel from '../../../components/MultiSelectBoxLabel';

const FilterWithID = ({ hanleSelect, listCoinObj, showCoin, listStatus, showType }: { hanleSelect: any, listCoinObj: any, showCoin: boolean, listStatus: any, showType: any }) => {
  const [reset, setreset] = useState('');
  const [status, setStatus]: any = useState({});
  const [type, setType]: any = useState({});
  const [isFilter, setIsFilter] = useState(false);
  const [totalSearch, setTotalSearch] = useState({
    fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
    toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
  });
  const [valueTime, setValueTime] = useState([
    new DateObject({ calendar: persian }).subtract(30, "days"),
    new DateObject({ calendar: persian }).add(0, "days")
  ])


  const listType = [
    { name: "واریز شتابی", value: "gateway" },
    { name: "واریز شناسه دار", value: "cashCode" },
    { name: "کارت به کارت", value: "card" },
  ]

  const handleReset = () => {
    setIsFilter(false)
    setValueTime([
      new DateObject({ calendar: persian }).subtract(30, "days"),
      new DateObject({ calendar: persian }).add(0, "days")
    ])
    setStatus({})
    setType({})
    setreset(new Date().getTime().toString());
    setTotalSearch({
      fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
      toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
    });
    hanleSelect({
      fromDate: { key: 'fromDate', value: Math.floor(moment().subtract(30, 'd').valueOf()) },
      toDate: { key: 'toDate', value: Math.floor(moment().add(0, 'm').valueOf()) }
    })
  }

  const handleFilterTime = (e: any) => {
    if (e) {
      let time2 = e[0]?.unix + 86400
      if (e[1] && e[1].unix > e[0]?.unix) {
        time2 = e[1].unix
      }
      handelSearch("fromDate", e[0]?.unix * 1000)
      handelSearch("toDate", time2 * 1000)
    }
  }

  const handelSearch = (key: string, value: any) => {
    setTotalSearch((prevState: any) => ({
      ...prevState,
      [key]: { key: key, value: value?.toString() }
    }));
  }

  return (
    <div className='filter'>
      {showCoin
        && <MultiSelectBoxSearch listData={listCoinObj ?? []}
          setInputSatet={(e: any) => handelSearch("coins", e)} className={""} reset={reset} />
      }
      {showType
        && <CustomSelectBoxLabel flag={false} element={
          <div className='showBanck'>
            <h4 className='p-0 letSpacing0' >{_.isEmpty(type) ? "نوع واریز" : type?.name}</h4>
          </div>}
          listData={listType} label={""} setInputSatet={(e: any) => { handelSearch("type", e?.value); setType(e) }} className={"styleBankSelect "} />
      }
      <MultiSelectBoxLabel listData={listStatus ?? []}
        setInputSatet={(e: any) => handelSearch("status", e)} className={""} reset={reset} />
      <DatePicker
        value={valueTime}
        onChange={handleFilterTime}
        range
        dateSeparator=" تا "
        calendar={persian}
        locale={persian_fa}
        calendarPosition="bottom-right"
      />
      <span className='btnSearchFilter' onClick={() => { hanleSelect(totalSearch); setIsFilter(true) }}>جستجو</span>
      {isFilter && <span className='iconClose ' onClick={() => handleReset()}></span>}
    </div>
  )
}

export default FilterWithID