import { IoIosArrowDown } from "react-icons/io";
import { ClickAwayListener } from '@mui/material';
import { useRef, useState } from 'react';
import ListCoinPopup from './ListCoinPopup';
import ListNetworkPopup from './ListNetworkPopup';



const CustomSelectBox2 =
  ({ element, setInputSatet, listData, className, error, flag, typeFee = "", symbol = '', readOnly = true, isDeposit = false,showFee=false }: any) => {

    const anchorRef = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false)

    const handleClose = (event: Event | React.SyntheticEvent) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      setOpen(false);
    };

    return (
      <div className={`customInput customInputSelect ${className} ${error && "brRed"}`} >
        <ClickAwayListener onClickAway={handleClose}>
          <div className='textViewBox' onClick={() => readOnly && setOpen(true)}>
            {element}
            <button ref={anchorRef} className='btnPopUp' ><span className="icon-Arrow2 cPointer"></span></button>
          </div>
        </ClickAwayListener>
        {flag
          ? <ListCoinPopup listData={listData} className={open ? "" : "none"} onClick={(e: any) => setInputSatet(e)} />
          : <ListNetworkPopup isDeposit={isDeposit} showFee={showFee} symbol={symbol} listData={listData} typeFee={typeFee} className={open ? "" : "none"} onClick={(e: any) => setInputSatet(e)} />}

      </div >
    )
  }

export default CustomSelectBox2;