
const ListNetworkPopup = ({ className, listData, onClick, typeFee, symbol, isDeposit,showFee }: any) => {

  const handleIsActive = (item: any) => {
    if (isDeposit) {
      if (item?.isActive && item?.deposit?.isActive) {
        return true
      }
      return false

    } else {
      if (item?.isActive && item?.withdraw?.isActive) {
        return true
      }
      return false
    }

  }

  return (
    <div className={`listNetworkPopup ${className} listCoinPopupV2`}>
      {listData?.map((i: any, n: any) =>
        <div key={n} className="item" onClick={() => handleIsActive(i) && onClick(i)}>
          <div className="symbolFee">
            <p>{i?.network?.name}</p>
            {showFee&&<p className="fee">کارمزد شبکه : {i?.[typeFee]?.fee} {symbol}</p>}
          </div>
          <p className={`isActive  ${handleIsActive(i) ? "" : "falseActive"}`}>{handleIsActive(i) ? "فعال" : "غیرفعال"}</p>
        </div>
      )}
    </div>
  )
}

export default ListNetworkPopup