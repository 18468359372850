import React, { useEffect, useState } from 'react'
import { bankAcoount } from '../../../config/variable'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import * as DialogShow from '../../../services/redux/dialog'
import WarningMaxDeposit from '../dialogs/WarningMaxDeposit'
import { useLocalStorage } from 'usehooks-ts'
import { Menu, Tooltip } from '@mui/material'
import QRCode from 'react-qr-code';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import useSnack from '../../../hooks/useSnack'
import _ from 'lodash'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { detect_bankName } from '../../../helpers/bankName'

const GeneratedID = ({ data, isNew, profile }: any) => {
  const dispatch = useDispatch<any>();
  const snackUse = useSnack();
  const [warning, setWarning, removeWrning] = useLocalStorage('warningDepo', "");
  const [dataList, setDataList] = useState([]);
  const listLevelObjByID = useSelector((state: any) => state.publicApis.listLevelObjByID);

  const handleDatadeposit = () => {
    let newList: any = []

    Array.from(data).forEach((e: any) => {
      Array.from(listLevelObjByID[profile?.plane?.level?._id]?.cashCode).forEach((element: any) => {
        if (element?.slug == e?.bank) {
          let newObj = _.cloneDeep(element)
          newObj["codeUser"] = e?.code;
          newList.push(newObj)
        }
      });
    });

    setDataList(newList);
  }

  const eleToolTip = (num: any) => {
    return (<div className='qrCodeGeneratedID'>
      <QRCode
        size={306}
        style={{ height: '73px', width: '73px' }}
        value={num}
        viewBox={`0 0 73 73`}
      />
    </div>)
  }

  useEffect(() => {
    if (warning == "true") {
      dispatch(DialogShow.show(<WarningMaxDeposit close={() => dispatch(DialogShow.hide())} />))
    }
  }, [])

  useEffect(() => {

    handleDatadeposit()
  }, [data, listLevelObjByID]);


  return (
    <>
      <div className='reCreateID'>
        {isNew && <div className='textOkCreate'>شناسه واریز شما با موفقیت ساخته شد</div>}
        {/* {data?.length < 3 && <button className='btnRequestID' >
          <img src="/icons/m-IconAddTag.svg" alt="" />
          <p>درخواست صدور شناسه واریز جدید</p>
        </button>} */}
      </div>
      {dataList.length > 0 ? Array.from(dataList)
        // ?.sort((a: any, b: any) => a.sort - b.sort)
        ?.map((i: any, n: number) =>
          <div key={n} className='generatedID'>
            <div className='item'>
              <p>وضعیت</p>
              <span className='dividerDash'></span>
              <p className='colorGreen'>{i?.isActive ? "فعال" : "غیرفعال"}</p>
            </div>
            <div className='item'>
              <p>شماره شبا مقصد</p>
              <span className='dividerDash'></span>
              <div className='imgOpatrtor'>
                <Tooltip title={eleToolTip(i?.sheba)}>
                  <QrCode2Icon className='cPointer' />
                </Tooltip>
                <CopyToClipboard text={i?.sheba} onCopy={() => { i?.sheba && snackUse.showSaccess("شماره شبا کپی شد") }}>
                  <span className='icon-IconCopy2 cPointer'></span>
                </CopyToClipboard>
                <p>{i?.sheba}</p>
                <img src={`${detect_bankName(i?.slug).bank_logo}`} alt="" />
              </div>
            </div>
            <div className='item'>
              <p>شماره حساب </p>
              <span className='dividerDash'></span>
              <div className='imgOpatrtor'>
                <Tooltip title={eleToolTip(i?.account)}>
                  <QrCode2Icon className='cPointer' />
                </Tooltip>
                <CopyToClipboard text={i?.account} onCopy={() => { i?.account && snackUse.showSaccess(" کپی شد") }}>
                  <span className='icon-IconCopy2 cPointer'></span>
                </CopyToClipboard>
                <p>{i?.account}</p>
              </div>
            </div>
            <div className='item'>
              <p>نام صاحب حساب</p>
              <span className='dividerDash'></span>
              <div className='imgOpatrtor'>
                <p>{i?.owner ?? "---"}</p>
              </div>
            </div>
            <div className='item'>
              <p>نام بانک</p>
              <span className='dividerDash'></span>
              <div className='imgOpatrtor'>
                <p>{i?.bank ?? "---"}</p>
              </div>
            </div>
            <div className='item'>
              <p>شناسه پرداخت/واریز <b className='txtforceGateway'>(الزامی)</b></p>
              <span className='dividerDash'></span>
              <div className='imgOpatrtor'>
                {/* <Tooltip title={eleToolTip(i?.codeUser)}>
                  <QrCode2Icon className='cPointer' />
                </Tooltip> */}
                <CopyToClipboard text={i?.codeUser} onCopy={() => { i?.codeUser && snackUse.showSaccess("شناسه پرداخت  کپی شد") }}>
                  <span className='icon-IconCopy2 cPointer'></span>
                </CopyToClipboard>
                <p>{i?.codeUser}</p>
              </div>
            </div>
          </div>)
        : <div className='emptyLoad'>
          <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" />
          <p>موردی یافت نشد</p>
        </div>}
    </>
  )
}

export default GeneratedID