import { NumericFormat } from 'react-number-format'
import { removeComma } from '../helpers/tools'
import { Fade, Tooltip } from '@mui/material'

const CustomInput = ({ inputState, setInputSatet, label, startEle, endEle, className, placeholder,
  error, classStart, classEnd, readOnly = false, id = "",tooltipTitle="", decimalScale = 30 }: any) => {
    
  return (
    <Tooltip arrow TransitionComponent={Fade} placement="top" TransitionProps={{ timeout: 400 }} enterDelay={100} leaveDelay={700}
      title={tooltipTitle}>
      <div className={`customInput ${className} ${error && "brRed"} ${readOnly && "opacity07"}`}>
        <label className="labelInput">{label}</label>
        {startEle && <div className={`startEle ${classStart}`}>{startEle}</div>}
        <NumericFormat decimalScale={decimalScale} className='inputNumeric' id={id} allowNegative={false} thousandSeparator={true} readOnly={readOnly}
          value={inputState > 0 ? inputState : ''} onChange={(e) => setInputSatet(removeComma(e.target.value))}
          placeholder={placeholder} />
        {endEle && <div className={`endEle ${classEnd}`}>{endEle}</div>}
      </div>
    </Tooltip>
  )
}

export default CustomInput