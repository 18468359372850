import NavChangeDeposit from './items/NavChangeDeposit';
import { useEffect, useState } from 'react';
import History from './items/History';
import _ from 'lodash';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';
import useSnack from '../../hooks/useSnack';
import { NavLink } from 'react-router-dom';
import CustomSelectBoxLabel from '../../components/CustomSelectBoxLabel';
import { useSelector } from 'react-redux';
import { detect_bank, detect_bankName } from '../../helpers/bankName';

const DepositCashCard = () => {
  const snackUse = useSnack();
  const cardBanckList = useSelector((state: any) => state.user.cards);
  const [card, setCard]: any = useState(Object.values(cardBanckList)[0] ?? {});

  useEffect(() => {
    document.title = menu.financial.childs.depositAll.childs.depositCash.childs.card.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox'>
                <NavChangeDeposit />
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>1</span></p>
                    <h5>انتخاب کارت مبدا</h5>
                  </div>
                  <NavLink className="addCard" to={menu.userBank.childs.account.url}>
                    <span className='icon-IconAddCard'></span>
                    <p>افزودن کارت</p>
                  </NavLink>
                </div>
                <CustomSelectBoxLabel element={
                  <div className='showBanck'>
                    {card?.number
                      ? <><h4 className='p-0 fontCard' >{card?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</h4>
                        <img className='m-0' src={`${!_.isEmpty(detect_bank(card?.number).bank_logo) ? detect_bank(card?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
                      </>
                      : <h4 className='defaultText'><b>شماره کارت خود را انتخاب کنید</b></h4>}
                  </div>}
                  error={false} listData={Object.values(cardBanckList) ?? []} label={"شماره کارت"} setInputSatet={setCard} className={" styleBankSelect mt-10"} />
                <p className='cashCardAlert'>شماره کارت باید به نام خودتان باشد و در اکسنوین ثبت شده باشد</p>
                <div className='addCardBox'>
                  <div className='stepDepWith'>
                    <p><span>2</span></p>
                    <h5> شماره کارت مقصد</h5>
                  </div>
                </div>
                <div className='generatedID'>
                  <div className='item'>
                    <p>وضعیت</p>
                    <span className='dividerDash'></span>
                    <p className='colorGreen'>فعال</p>
                  </div>
                  <div className='item'>
                    <p>شماره کارت مقصد</p>
                    <span className='dividerDash'></span>
                    <div className='imgOpatrtor'>
                      <CopyToClipboard text={"6037697090121291"} onCopy={() => { snackUse.showSaccess("شماره کارت کپی شد") }}>
                        <span className='icon-IconCopy2'></span>
                      </CopyToClipboard>
                      <p>{"6037-6970-9012-1291"}</p>
                      <img src={`${detect_bankName("saderat").bank_logo}`} alt="" />
                    </div>
                  </div>
                  <div className='item'>
                    <p>نام صاحب کارت</p>
                    <span className='dividerDash'></span>
                    <div className='imgOpatrtor'>
                      <p>شرکت داد و ستد فناوران پارس نوین</p>
                    </div>
                  </div>
                  <div className='item'>
                    <p>نام بانک</p>
                    <span className='dividerDash'></span>
                    <div className='imgOpatrtor'>
                      <p>بانک صادرات</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='detailBox'>
                <img className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                <div className='minDepoText denger1'>
                  <img src="/icons/danger.svg" alt="" />
                  <p>برای واریز لطفا از سامانه پل استفاده نکنید. در صورت واریز مبلغ شارژ نخواهد شد.</p>
                </div>
                <div className='infoText'>
                  <h5><b>نکات</b> مهم</h5>
                  <p>لطفا دقت کنید کارت بانکی مبدا بنام شما و حتما در پروفایل کاربری شما ثبت و تاییده شده باشد.</p>
                  <p>سقف واریزی روزانه از هر کارت<span className='colorRed'> 10,000,000 تومان </span>  است. به طور مثال : از 5 کارت مختلف میتوانید 5 بار 10 میلیون واریز کنید.</p>
                  <p>پس از انجام کارت به کارت ، تا <span className='colorRed'> 5 دقیقه </span> منتظر بمانید تا سیستم به صورت خودکار واریزی شما را شناسایی و مبلغ به کیف پول شما اضافه کند.</p>
                  <p>سعی کنید از طریق <span className='colorRed'>اپلیکیشن های همراه بانک یا عابر بانک ATM</span> ، کارت به کارت کنید. متاسفانه از اپ های ماننده آپ ، سکه ، همراه کارت و ... محدودیت های مختلفی دارند.</p>
                </div>
                <div className='infoText'>
                  <h5>کارمزد 0% کارت به کارت</h5>
                  <p>در حال حاضر کارمزد سرویس کارت به کارت  رایگان است.</p>
                </div>
              </div>
            </div>
            <History flag={true} reload={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DepositCashCard
